import React from 'react';
import { TaskInventory as TaskInventoryType } from '@bringg/types/types/task';
import CardWrapper from '../../card/card-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faWeight } from '@fortawesome/pro-light-svg-icons/faWeight';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import _get from 'lodash/get';
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons/faBarcodeScan';
import { TASK_INVENTORY_CONSTANTS } from './task-inventory-form';
import Label from '../../label/label';
import { Controller, UseFormMethods } from 'react-hook-form';
import NumberInput from '../../number-input/number-input';
import Input from '../../floating-placeholder-input/input';
import { Translations } from '../task-inventory';
import { TaskInventoriesFlagsAccess } from '../../../features/waypoint-card/wapoint-card';

interface Props {
	taskInventory: TaskInventoryType;
	depth: number;
	translations: Translations;
	form: UseFormMethods;
	onDelete: (taskInventoryID: number) => void;
	addToTaskInventoryMap: (taskInventoryID: number, taskInventory: TaskInventoryType) => void;
	flagsAccess: TaskInventoriesFlagsAccess;
}

const TaskInventoryFormItems: React.FC<Props> = ({
	taskInventory,
	depth,
	translations,
	form,
	onDelete,
	addToTaskInventoryMap,
	flagsAccess
}: Props) => {
	const getCardBody = taskInventory => (
		<div className="task-inventory-card-body">
			<div className="task-inventory-card-sizes">
				<FontAwesomeIcon icon={faWeight} />
				<div className="task-inventory-card-size task-inventory-card-width">
					{getController(
						translations.width,
						form,
						`${taskInventory.id}-` + TASK_INVENTORY_CONSTANTS.width,
						taskInventory.width || taskInventory.inventory.width
					)}
				</div>
				<div className="task-inventory-card-size task-inventory-card-height">
					{getController(
						translations.height,
						form,
						`${taskInventory.id}-` + TASK_INVENTORY_CONSTANTS.height,
						taskInventory.height || taskInventory.inventory.height
					)}
				</div>
				<div className="task-inventory-card-size task-inventory-card-length">
					{getController(
						translations.inventoryLength,
						form,
						`${taskInventory.id}-` + TASK_INVENTORY_CONSTANTS.length,
						taskInventory.length || taskInventory.inventory.length
					)}
				</div>
			</div>
			<div className="task-inventory-card-details">
				<div className="task-inventory-card-details-item">
					<FontAwesomeIcon icon={faCoins} />
					<span className="task-inventory-card-details-item-span">
						{_get(taskInventory, 'price') || 'N.A'}
					</span>
				</div>
				<div className="task-inventory-card-details-item">
					<FontAwesomeIcon icon={faBarcodeScan} />
					<span>{_get(taskInventory, 'scan_string') || 'N.A'}</span>
				</div>
			</div>
		</div>
	);
	const getCardHeader = taskInventory => (
		<div className="task-inventory-card-header">
			<div className="task-inventory-card-content">
				<span>
					{taskInventory.pending ? (
						<Label text={`${translations.collectText}`} size="small" className="orange" />
					) : (
						<Label text={`${translations.deliverText}`} size="small" className="green" />
					)}
				</span>
				<span className="task-inventory-card-name">{`${taskInventory.original_quantity} x ${_get(
					taskInventory,
					'name',
					taskInventory.inventory_id
				)}`}</span>
				<span className="task-inventory-card-id">#{_get(taskInventory, 'external_id')}</span>
			</div>
			<div>
				<div className="task-inventory-edit-quantity">
					<Controller
						render={({ value, onChange }) => (
							<NumberInput
								title={translations.quantity}
								minValue={0}
								value={value}
								onChange={onChange}
								isDisabled={!flagsAccess.enable_dispatcher_edit_task_inventory_quantity}
							/>
						)}
						control={form.control}
						defaultValue={taskInventory.original_quantity}
						name={`${taskInventory.id}-` + TASK_INVENTORY_CONSTANTS.originalQuantity}
					/>
				</div>
			</div>
		</div>
	);
	const getController = (placeholder: string, form: UseFormMethods, name: string, defaultValue: number) => (
		<Controller
			as={
				<Input
					type="number"
					placeholder={placeholder}
					className="full-border-container"
					inputClassName="full-border"
					isDisabled={!flagsAccess.enable_dimension_editing_in_ui}
				/>
			}
			control={form.control}
			name={name}
			defaultValue={defaultValue || 0}
		/>
	);
	return (
		<>
			{addToTaskInventoryMap(taskInventory.id, taskInventory)}
			<CardWrapper
				style={{ marginLeft: `${depth * 3}%` }}
				title={getCardHeader(taskInventory)}
				data-test-id={_get(taskInventory, taskInventory.inventory_id)}
				extra={
					<FontAwesomeIcon
						className="trash-icon"
						icon={faTrashAlt}
						onClick={() =>
							flagsAccess.enable_dispatcher_edit_task_inventory_quantity && onDelete(taskInventory.id)
						}
					/>
				}
			>
				{getCardBody(taskInventory)}
			</CardWrapper>
			{taskInventory.inventories?.length > 0 &&
				taskInventory.inventories.map(subTaskInventory => (
					<TaskInventoryFormItems
						key={subTaskInventory.id.toString()}
						translations={translations}
						onDelete={onDelete}
						taskInventory={subTaskInventory}
						form={form}
						depth={depth + 1}
						addToTaskInventoryMap={addToTaskInventoryMap}
						flagsAccess={flagsAccess}
					/>
				))}
		</>
	);
};

export default TaskInventoryFormItems;
