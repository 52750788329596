import React, { useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { Map as MapboxMap, Marker as MapboxMarker, NavigationControl, Popup as MapboxPopup } from 'mapbox-gl';
import _isFunction from 'lodash/isFunction';
import _isNumber from 'lodash/isNumber';
import { BringgMapProps, IdType, ImageUrl, MarkerData, Popup } from '../../types/maps.consts';
import { useMapboxLoadData } from '../../custom-hooks/mapbox/use-mapbox-load-data/use-mapbox-load-data';

export type MapboxImage = string;

export interface MarkerInstance {
	id: IdType;
	mapboxMarker?: MapboxMarker;
	mapboxPopup?: MapboxPopup;
	sourceId?: string;
}

export type MapboxProps = Omit<BringgMapProps<MapboxMap>, 'markers'> & {
	mapboxOptions: {
		style: string;
	};
	markers?: MapboxMarkerData[] | Promise<MapboxMarkerData[]>;
};

export type MapboxMarkerData = MarkerData & {
	popup?: Popup;
};

const Mapbox: React.FC<MapboxProps> = ({
	mapboxOptions,
	apiKey,
	zoom,
	zoomControl,
	center,
	shouldCluster,
	clusterBelowZoom,
	onLoaded,
	markers,
	polylines,
	className,
	children
}) => {
	const { style } = mapboxOptions;
	const [map, setMap] = useState<MapboxMap>();
	const markersMap: Map<IdType, MarkerInstance> = useMemo(() => new Map(), []);
	const imagesMap: Map<ImageUrl, MapboxImage> = useMemo(() => new Map(), []);
	const refContainer = useRef();

	useMapboxLoadData({
		map,
		markersMap,
		imagesMap,
		markers,
		polylines,
		shouldCluster,
		clusterBelowZoom
	});

	useEffect(() => {
		if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
			mapboxgl.setRTLTextPlugin(
				'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
				null,
				true
			);
		}

		const map = new MapboxMap({
			accessToken: apiKey,
			style,
			container: refContainer.current,
			center: center ? [center.lng, center.lat] : undefined,
			zoom
		});

		if (zoomControl) {
			map.addControl(
				new NavigationControl({
					showCompass: false,
					showZoom: true
				}),
				'top-left'
			);
		}

		map.on('load', () => {
			setMap(map);

			if (_isFunction(onLoaded)) {
				onLoaded(map);
			}
		});
	}, []);

	useEffect(() => {
		if (!map) {
			return;
		}

		if (_isNumber(zoom)) {
			map.setZoom(zoom);
		}
	}, [map, zoom]);

	useEffect(() => {
		if (!map) {
			return;
		}

		if (center) {
			map.panTo(center);
		}
	}, [map, center]);

	return (
		<div ref={refContainer} className={className}>
			{children}
		</div>
	);
};

Mapbox.defaultProps = {
	zoomControl: true
};

export default Mapbox;
