import React from 'react';
import { Input, InputNumber, Checkbox, Switch, Button, FormInstance } from 'antd';
import FormWithValidationWrapper, { FormChildren } from '../../components/form/form';
import _isNumber from 'lodash/isNumber';
import { requiredRule, regexRule, validateType } from '../../services/validation-rules';
import { FormLayout } from 'antd/es/form/Form';
import { FieldData } from '@bringg/types';
import Select from '../../components/select/select';
import Signature from '../../components/signature/signature';
import ImageUploader from '../../components/image-uploader/image-uploader';
import classNames from 'classnames';
import { Store } from 'rc-field-form/lib/interface';

interface Props {
	fields: FormFields[];
	title?: string | JSX.Element;
	onSubmit?: (values: any) => void;
	onCancel?: () => void;
	showSubmitButton?: boolean;
	showCancelButton?: boolean;
	cancelButton?: JSX.Element;
	submitButton?: JSX.Element;
	formInstance: FormInstance;
	layout?: FormLayout;
	numberOfColumns?: number;
	resetFields?: boolean;
	initialValues?: Store;
}

export type FormFields = FieldData & {
	regex?: RegExp;
	formItemClassName?: string;
	disabled?: boolean;
	initialValue?: any;
	valuePropName?: string;
};

export enum FORM_CONTROLS {
	INPUT,
	CHECKBOX,
	SWITCH,
	RADIO,
	DROP_DOWN,
	SIGNATURE,
	PICTURE,
	LABEL,
	LOGO,
	OPEN_APP
}

const NOT_FORM_CHILDREN_CONTROLS = [FORM_CONTROLS.LABEL, FORM_CONTROLS.OPEN_APP];

export enum StringType {
	number = 0,
	string = 1,
	boolean = 2
}

class DynamicForm extends React.PureComponent<Props> {
	static defaultProps = {
		resetFields: true
	};

	getFieldControl = (field: FormFields) => {
		const {
			key,
			control,
			type,
			label,
			list_values,
			disclaimer,
			title,
			disabled,
			mandatory,
			url,
			formItemClassName
		} = field;

		switch (control) {
			case FORM_CONTROLS.INPUT:
				return this.getInputComponent(type);

			case FORM_CONTROLS.CHECKBOX:
				return <Checkbox />;

			case FORM_CONTROLS.SWITCH:
				return <Switch />;

			case FORM_CONTROLS.DROP_DOWN:
				return <Select options={this.generateSelectOptions(list_values, key)} data-testId={`select_${key}`} />;

			case FORM_CONTROLS.SIGNATURE:
				return (
					<Signature
						disclaimer={disclaimer}
						title={title}
						disabled={disabled}
						mandatory={mandatory}
						id={key}
					/>
				);

			case FORM_CONTROLS.PICTURE:
				return <ImageUploader title={title} mandatory={mandatory} id={key} />;

			case FORM_CONTROLS.LABEL:
				return (
					<p key={key} className={classNames('label-control', formItemClassName)}>
						{label}
					</p>
				);
			case FORM_CONTROLS.OPEN_APP:
				return (
					<Button key={key} className={classNames('open-app-control', formItemClassName)} id={key}>
						<a href={url}>{title}</a>
					</Button>
				);

			default:
				return <Input />;
		}
	};

	getTypeString = (type: StringType) => {
		switch (type) {
			case StringType.number:
				return 'number';
			case StringType.string:
				return 'string';
			case StringType.boolean:
				return 'boolean';
			default:
				return 'string';
		}
	};

	generateSelectOptions = (options: string[], dropDownKey) =>
		options.map(option => ({ id: option, name: option, dropDownKey }));

	getInputComponent = type => (type === 0 ? <InputNumber /> : <Input />);

	getRules = (field: FormFields) => {
		const { type, mandatory, regex } = field;
		let rules = [];

		if (_isNumber(type)) {
			rules = [...rules, validateType(this.getTypeString(type))];
		}
		if (mandatory) {
			rules = [...rules, requiredRule];
		}
		if (regex) {
			rules = [...rules, regexRule(regex)];
		}

		return rules;
	};

	createFormChildren = (field: FormFields) => {
		const { getFieldControl, getRules } = this;
		const { key, label, formItemClassName, valuePropName } = field;

		return (
			<FormChildren
				formItemsProps={{
					name: key,
					label: label,
					rules: getRules(field),
					initialValue: field.default_value || field.initialValue,
					className: formItemClassName,
					valuePropName
				}}
				key={key}
				id={key}
			>
				{getFieldControl(field)}
			</FormChildren>
		);
	};

	getField = (field: FormFields) => {
		if (NOT_FORM_CHILDREN_CONTROLS.includes(field.control)) {
			return this.getFieldControl(field);
		}

		return this.createFormChildren(field);
	};

	render() {
		const { getField } = this;
		const {
			fields,
			title,
			submitButton,
			cancelButton,
			numberOfColumns,
			layout,
			resetFields,
			onSubmit,
			initialValues,
			formInstance
		} = this.props;

		return (
			<div className="dynamic-form">
				{title ? (
					<div>
						<h2>{title}</h2>
					</div>
				) : null}

				<FormWithValidationWrapper
					numberOfColumns={numberOfColumns}
					onSubmit={onSubmit}
					submitButton={submitButton}
					cancelButton={cancelButton}
					layout={layout}
					resetOnErr={resetFields}
					initialValues={initialValues}
					form={formInstance}
				>
					{fields.map(getField)}
				</FormWithValidationWrapper>
			</div>
		);
	}
}

export default DynamicForm;
