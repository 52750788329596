import React, { useCallback, useEffect, useState } from 'react';
import {
	Inventory,
	NoteType,
	SharedLocation,
	TaskInventory,
	TaskNote,
	WayPoint,
	AutoCompleteAddress,
	BringgGeocodedAddress,
	Task
} from '@bringg/types';
import CardWrapper from '../../components/card/card-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import Row from '../../components/row/row';
import Col from '../../components/col/col';
import { default as _get } from 'lodash/get';
import { default as _partition } from 'lodash/partition';
import { default as _noop } from 'lodash/noop';
import { Translations as TaskNotesTranslations } from '../../components/task-notes/task-notes';
import WayPointTimes, {
	Translations as WayPointTimesTranslations
} from '../../components/waypoint-times/waypoint-times';
import CustomerContact, {
	Translations as CustomerContactTranslations
} from '../../components/customer-contact/customer-contact';
import { Translations as TaskInventoriesTranslations } from '../../components/task-inventories/task-inventories';
import { Translations as TaskNotesAttachmentsTranslations } from '../../components/task-notes-attachments/task-notes-attachments';
import Modal from '../../components/modal/modal';
import WayPointCardForm, {
	Translations as WayPointCardFormTranslations
} from './waypoint-card-form/waypoint-card-form';
import { Translations as TaskTrackingLinksTranslations } from '../../components/task-tracking-links/task-tracking-links';
import { MapData } from '../../components/mini-static-map-link/mini-static-map-link';
import Label from '../../components/label/label';
import WaypointCardTabs from './waypoint-card-tabs';
import { PickupDropoffOption } from '@bringg/types/types/task';
import { TimezoneContext } from './hooks/use-timezone';

export interface Translations
	extends TaskNotesTranslations,
		CustomerContactTranslations,
		WayPointTimesTranslations,
		TaskInventoriesTranslations,
		TaskNotesAttachmentsTranslations,
		WayPointCardFormTranslations,
		TaskTrackingLinksTranslations {
	notes: string;
	inventories: string;
	attachments: string;
	trackingLinks: string;
	editStop: string;
	of: string;
	stop: string;
}

export interface TaskInventoriesFlagsAccess {
	enable_dispatcher_edit_task_inventory_quantity: boolean;
	enable_dimension_editing_in_ui: boolean;
}

interface WayPointCardProps {
	wayPoint: WayPoint;
	translations: Translations;
	numberOfStops?: number;
	onSave?: (wayPoint: WayPoint) => void;
	onDelete?: (wayPointId: number) => void;
	onQuantityChange?: (massQuantityUpdate: TaskInventory[]) => void;
	onInventoriesChange?: (inventories: Inventory[]) => void;
	onNoteAdded?: (note: string) => void;
	timeZone?: string;
	hourFormat: string;
	taskNotes?: TaskNote[];
	taskInventories?: TaskInventory[];
	sharedLocations?: SharedLocation[];
	mapData?: MapData;
	fetchAddresses?: (query: string) => Promise<AutoCompleteAddress[]>;
	getPlaceDetails?: (placeId: string) => Promise<BringgGeocodedAddress>;
	configuration: OrderPageConfiguration;
	task?: Task;
}

export interface OrderPageConfiguration {
	permissions: OrderPagePermissions;
	flags: OrderPageFlags;
}
export interface OrderPagePermissions {
	edit: boolean;
	editInventoryQuantity: boolean;
	editDimension: boolean;
}
export interface OrderPageFlags {
	useTeamTimezone: boolean;
}

const getAddressSecondLine = (wayPoint: WayPoint): string => {
	let result = wayPoint.address_second_line || '';

	result = addFieldToAddress(result, wayPoint.borough);
	result = addFieldToAddress(result, wayPoint.city);
	result = addFieldToAddress(result, wayPoint.zipcode);

	return result;
};

const addFieldToAddress = (currentAddress: string, field: string): string => {
	if (!field) {
		return currentAddress;
	}

	if (currentAddress) {
		currentAddress += ', ';
	}

	currentAddress += field;

	return currentAddress;
};
const tabPaneTitle = (title: string, icon): React.ReactNode => {
	return (
		<span>
			<FontAwesomeIcon icon={icon} />
			{title}
		</span>
	);
};
const WayPointCard: React.FC<WayPointCardProps> = ({
	wayPoint,
	numberOfStops = 1,
	onSave = _noop,
	onDelete = _noop,
	onQuantityChange = _noop,
	onInventoriesChange = _noop,
	onNoteAdded = _noop,
	timeZone,
	taskNotes,
	hourFormat,
	taskInventories,
	sharedLocations,
	mapData,
	translations,
	fetchAddresses = () => Promise.resolve([]),
	getPlaceDetails = () => Promise.resolve({}),
	configuration,
	task
}: WayPointCardProps) => {
	const [showEditForm, setShowEditForm] = useState(false);
	const [wayPointData, setWayPointData] = useState(wayPoint);
	const { edit: editable, editInventoryQuantity, editDimension } = configuration.permissions;
	const timezoneForDisplay = configuration.flags.useTeamTimezone && timeZone;

	useEffect(() => {
		setWayPointData(wayPoint);
	}, [wayPoint]);

	const showForm = () => {
		setShowEditForm(true);
	};

	const hideForm = () => {
		setShowEditForm(false);
	};

	const onFormSave = (wayPoint: WayPoint) => {
		hideForm();
		setWayPointData(wayPoint);
		onSave(wayPoint);
	};

	const onFormDelete = (wayPointId: number) => {
		hideForm();
		onDelete(wayPointId);
	};

	const onEditClick = event => {
		showForm();
		event.preventDefault();
	};

	const getLabel = useCallback(() => {
		const { pickup_dropoff_option } = wayPointData;

		switch (pickup_dropoff_option) {
			case PickupDropoffOption.PICKUP:
				return <Label text={translations.collectText} size="small" className="orange" />;

			case PickupDropoffOption.DROPOFF:
				return <Label text={translations.deliverText} size="small" className="green" />;

			case PickupDropoffOption.BOTH:
				return (
					<>
						<Label text={translations.collectText} size="small" className="orange" />
						<Label text={translations.deliverText} size="small" className="green" />
					</>
				);
		}
	}, [wayPointData, translations]);

	const stopNumber = wayPointData.position > numberOfStops ? numberOfStops : wayPointData.position;

	const cardTitle = (
		<div>
			<div className="waypoint-edit-container">
				<div className="waypoint-edit-stops">
					<span>{`${translations.stop} ${stopNumber} ${translations.of} ${numberOfStops}`}</span>

					{getLabel()}
				</div>
				{editable && (
					<div className="waypoint-edit-button">
						<span className="waypoint-edit-link" onClick={onEditClick}>
							{translations.editStop}
							<FontAwesomeIcon className="waypoint-edit-link-icon" icon={faPencilAlt} />
						</span>
					</div>
				)}
			</div>

			<div className="waypoint-address">{wayPointData.address}</div>
			<div className="waypoint-address-second-line">{getAddressSecondLine(wayPointData)}</div>
		</div>
	);

	const [textualTaskNotes, graphicalTaskNotes] = _partition(taskNotes, note =>
		[NoteType.TaskNote, NoteType.HtmlTaskNote].includes(note.type)
	);

	return (
		<TimezoneContext.Provider value={timeZone}>
			<div className="waypoint-card">
				<CardWrapper title={cardTitle}>
					<Row>
						<Col className="waypoint-card-item" span={16}>
							<WayPointTimes
								scheduledAt={wayPointData.scheduled_at}
								eta={wayPointData.eta}
								hourFormat={hourFormat}
								noLaterThan={wayPointData.no_later_than}
								noEarlierThan={wayPointData.no_earlier_than}
								etl={wayPointData.etl}
								checkinTime={wayPointData.checkin_time}
								checkoutTime={wayPointData.checkout_time}
								timeZone={timeZone}
								translations={translations}
								useTeamTimezone={configuration.flags.useTeamTimezone}
								scheduledArrival={wayPoint.scheduled_arrival}
							/>
						</Col>

						<Col className="waypoint-card-item" span={8}>
							<CustomerContact
								name={wayPointData.name || _get(wayPointData, 'customer.name')}
								phone={wayPointData.phone || _get(wayPointData, 'customer.phone')}
								translations={translations}
							/>
						</Col>
					</Row>

					<WaypointCardTabs
						translations={translations}
						textualTaskNotes={textualTaskNotes}
						onNoteAdded={onNoteAdded}
						hourFormat={hourFormat}
						mapData={mapData}
						tabPaneTitle={tabPaneTitle}
						wayPointData={wayPointData}
						editable={editable}
						timezoneForDisplay={timezoneForDisplay}
						graphicalTaskNotes={graphicalTaskNotes}
						taskInventories={taskInventories}
						onQuantityChange={onQuantityChange}
						onInventoriesChange={onInventoriesChange}
						editInventoryQuantity={editInventoryQuantity}
						editDimension={editDimension}
						sharedLocations={sharedLocations}
						timeZone={timeZone}
						configuration={configuration}
						task={task}
					/>
				</CardWrapper>
			</div>

			{editable && (
				<Modal
					title={translations.editStop}
					className="waypoint-edit-modal"
					visible={showEditForm}
					onOk={hideForm}
					onCancel={hideForm}
					footer={null}
				>
					<WayPointCardForm
						wayPoint={wayPointData}
						onCancel={hideForm}
						onDelete={onFormDelete}
						onSave={onFormSave}
						translations={translations}
						fetchAddresses={fetchAddresses}
						getPlaceDetails={getPlaceDetails}
					/>
				</Modal>
			)}
		</TimezoneContext.Provider>
	);
};

export default WayPointCard;
