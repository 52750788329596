import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import React from 'react';
import classNames from 'classnames';

export enum MODES {
	EDIT = 'edit',
	VIEW = 'view'
}

export interface EditButtonProps {
	onSave: (event) => any;
	onCancel: (event) => void;
	onEdit: (event) => void;
	mode: MODES;
	title?: string;
}

const EditButton: React.FC<EditButtonProps> = ({ onSave, onEdit, onCancel, mode, title }) => {
	const titleElement = title ? (
		<div className={classNames('edit-title', mode)} onClick={onEdit}>
			{title}
		</div>
	) : null;

	return (
		<div className="edit-button-container">
			{titleElement}
			<div className={classNames('edit-button', mode)} onClick={onEdit}>
				<FontAwesomeIcon className="icon" icon={faPen} />
			</div>
			<div className={classNames('save-button', mode)} onClick={onSave}>
				<FontAwesomeIcon className="icon" icon={faCheck} />
			</div>
			<div className={classNames('cancel-button', mode)} onClick={onCancel}>
				<FontAwesomeIcon className="icon" icon={faTimes} />
			</div>
		</div>
	);
};

export default EditButton;
