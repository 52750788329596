import { Location as BringgLocation } from '@bringg/types';

export interface BringgMapProps<MapInstanceType = unknown> {
	apiKey: string;
	markers?: MarkerData[];
	polylines?: PolylineData[];
	center?: BringgLocation;
	zoom?: number;
	zoomControl?: boolean;
	clusterBelowZoom?: number;
	shouldCluster?: boolean;
	className?: string;
	onLoaded?: (mapInstance?: MapInstanceType) => void;
	onZoomChanged?: (zoomLevel: number) => void;
}

export type IdType = string | number;
export type ImageUrl = string;
export type MapboxIconOptions = { pixelRatio?: number; sdf?: boolean };

export interface MarkerData {
	id: IdType;
	location: BringgLocation;
	color?: string;
	textColor?: string;
	text?: string;
	anchor?: Anchor;
	icon?: JSX.Element | ImageUrl;
	iconOptions?: MapboxIconOptions;
	size?: Size;
}

export interface PolylineData {
	id: IdType;
	path: Bringg.Location[];
	color?: string;
	strokeOpacity?: number;
	strokeWeight?: number;
}

export interface Popup {
	element: JSX.Element;
	anchor?: Anchor;
	offset?: Point;
}

export interface Point {
	x: number;
	y: number;
}

export type Anchor = 'center' | 'left' | 'right' | 'top' | 'bottom';
export const defaultAnchor: Anchor = 'bottom';
export const defaultTextColor: string = '#000000';

export type Size = {
	width: number;
	height: number;
};
