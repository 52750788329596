import getTaskNoteTab from './waypoint-tabs/tasknote-tab';
import { getDateFormat } from '../../components/date-display/date-display';
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons/faStickyNote';
import Tabs, { TabPane } from '../../components/tabs/tabs';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import Row from '../../components/row/row';
import Col from '../../components/col/col';
import TaskNotesAttachments from '../../components/task-notes-attachments/task-notes-attachments';
import { filter as _filter, sumBy as _sumBy } from 'lodash';
import { faBox } from '@fortawesome/pro-solid-svg-icons/faBox';
import TaskInventories from '../../components/task-inventories/task-inventories';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import TaskTrackingLinks from '../../components/task-tracking-links/task-tracking-links';
import React from 'react';
import { OrderPageConfiguration, Translations } from './wapoint-card';
import { Inventory, SharedLocation, TaskInventory, TaskNote, WayPoint, Task } from '@bringg/types';
import { MapData } from '../../components/mini-static-map-link/mini-static-map-link';

interface Props {
	translations: Translations;
	textualTaskNotes: TaskNote[];
	onNoteAdded?: (note: string) => void;
	hourFormat: string;
	mapData?: MapData;
	tabPaneTitle: (title: string, icon) => React.ReactNode;
	wayPointData: WayPoint;
	editable: boolean;
	timezoneForDisplay?: string;
	graphicalTaskNotes: TaskNote[];
	taskInventories?: TaskInventory[];
	onQuantityChange?: (massQuantityUpdate: TaskInventory[]) => void;
	onInventoriesChange?: (inventories: Inventory[]) => void;
	editInventoryQuantity: boolean;
	editDimension: boolean;
	sharedLocations?: SharedLocation[];
	timeZone?: string;
	configuration: OrderPageConfiguration;
	task?: Task;
}

const WaypointCardTabs: React.FC<Props> = ({
	translations,
	textualTaskNotes,
	onNoteAdded,
	hourFormat,
	mapData,
	tabPaneTitle,
	wayPointData,
	editable,
	timezoneForDisplay,
	graphicalTaskNotes,
	taskInventories,
	onQuantityChange,
	onInventoriesChange,
	editInventoryQuantity,
	editDimension,
	sharedLocations,
	timeZone,
	configuration,
	task
}) => {
	const getInventorySum = (): number => {
		return _sumBy(_filter(taskInventories, { parent_task_inventory_id: null }), obj => obj.original_quantity);
	};

	return (
		<Tabs>
			{getTaskNoteTab({
				translations: translations,
				taskNotes: textualTaskNotes,
				onNoteAdded: onNoteAdded,
				dateFormat: getDateFormat(hourFormat),
				mapData: mapData,
				tabComponent: tabPaneTitle(` ${translations.notes} (${textualTaskNotes.length})`, faStickyNote),
				wayPointDataID: wayPointData.id,
				editable: editable,
				timezone: timezoneForDisplay
			})}
			<TabPane
				tab={tabPaneTitle(` ${translations.attachments} (${graphicalTaskNotes.length})`, faPaperclip)}
				key={`waypoint-${wayPointData.id}-attachments`}
			>
				<Row gutter={24}>
					<Col span={24}>
						<TaskNotesAttachments
							taskNotesAttachments={graphicalTaskNotes}
							dateFormat={getDateFormat(hourFormat)}
							mapData={mapData}
							translations={translations}
							timezone={timezoneForDisplay}
						/>
					</Col>
				</Row>
			</TabPane>
			<TabPane
				tab={tabPaneTitle(` ${translations.inventories} (${getInventorySum()})`, faBox)}
				key={`waypoint-${wayPointData.id}-inventories`}
			>
				<TaskInventories
					taskInventories={taskInventories}
					onQuantityChange={onQuantityChange}
					onInventoriesChange={onInventoriesChange}
					translations={translations}
					editable={editable}
					flagsAccess={{
						enable_dispatcher_edit_task_inventory_quantity: editInventoryQuantity,
						enable_dimension_editing_in_ui: editDimension
					}}
					wayPointData={wayPointData}
					task={task}
				/>
			</TabPane>
			{sharedLocations && (
				<TabPane
					tab={tabPaneTitle(` ${translations.trackingLinks} (${sharedLocations.length})`, faMapMarkerAlt)}
					key={`waypoint-${wayPointData.id}-tracking-links`}
				>
					<TaskTrackingLinks
						sharedLocations={sharedLocations}
						timeZone={timeZone}
						dateFormat={getDateFormat(hourFormat)}
						translations={translations}
						useTeamTimezone={configuration.flags.useTeamTimezone}
					/>
				</TabPane>
			)}
		</Tabs>
	);
};
export default WaypointCardTabs;
