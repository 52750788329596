import * as React from 'react';
import { List } from 'antd';
import { CSSProperties, MutableRefObject, useCallback, useRef } from 'react';
import classNames from 'classnames';
import _isFunction from 'lodash/isFunction';

interface SelectableListProps {
	items: any[];
	onSelect: (item: any) => void;
	renderValue?: (item: any) => any;
	className?: string;
	style?: CSSProperties;
	itemClassName?: string;
	itemStyle?: CSSProperties;
}

interface SelectableListItemProps {
	item: any;
	renderValue: (item: any) => any;
	onItemSelected: (item: any, ref: MutableRefObject<HTMLDivElement>) => void;
	className: string;
	style: CSSProperties;
}

const SelectableListItem: React.FC<SelectableListItemProps> = ({
	item,
	renderValue,
	onItemSelected,
	className,
	style
}) => {
	const listItemRef = useRef<HTMLDivElement>(null);

	const handleOnClick = () => onItemSelected(item, listItemRef);

	return (
		<div
			ref={listItemRef}
			onClick={handleOnClick}
			className={classNames('selectable-list-item', className)}
			style={style}
		>
			{_isFunction(renderValue) ? renderValue(item) : item}
		</div>
	);
};

const SelectableList: React.FC<SelectableListProps> = ({
	items,
	onSelect,
	className,
	style,
	itemClassName,
	itemStyle,
	renderValue
}) => {
	let selectItemRef = null;

	const handleItemRender = (className: string, style: CSSProperties = {}) => item => {
		return (
			<SelectableListItem
				item={item}
				renderValue={renderValue}
				onItemSelected={handleItemSelected}
				className={className}
				style={style}
			/>
		);
	};

	const handleItemSelected = useCallback((item, ref) => {
		if (selectItemRef) {
			unSelectListItem();
		}

		ref.current.classList.add('selected');
		selectItemRef = ref;
		onSelect(item);
	}, []);

	const unSelectListItem = useCallback(() => {
		selectItemRef.current.classList.remove('selected');
	}, []);

	return (
		<List
			dataSource={items}
			renderItem={handleItemRender(itemClassName, itemStyle)}
			className={classNames('selectable-list', className)}
			style={style}
		/>
	);
};

export default SelectableList;
