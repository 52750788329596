import React from 'react';
import { AutoComplete } from 'antd';
import UserAvatarInfo from '../user-avatar-info/user-avatar-info';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import _map from 'lodash/map';

interface Props extends AutoCompleteProps {
	users?: Bringg.User[];
}

const renderOption = (user: Bringg.User) => {
	return {
		className: 'users-autocomplete-option',
		value: user.id.toString(),
		label: (
			<>
				<UserAvatarInfo user={user} />
				<div className="users-select-plus-icon">
					<FontAwesomeIcon icon={faPlus} />
				</div>
			</>
		)
	};
};

const UsersAutoComplete = (props: Props) => {
	return <AutoComplete {...props} options={_map(props.users, renderOption)} />;
};

export default UsersAutoComplete;
