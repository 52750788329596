import ReactDOM from 'react-dom';
import {
	Marker as MapboxMarker,
	Popup as MapboxPopup,
	Map as MapboxMap,
	PointLike,
	GeoJSONSource,
	MapLayerMouseEvent,
	LngLatLike
} from 'mapbox-gl';
import { MapboxImage, MapboxMarkerData, MarkerInstance } from '../mapbox';
import {
	defaultAnchor,
	defaultTextColor,
	IdType,
	ImageUrl,
	MapboxIconOptions,
	PolylineData,
	Popup
} from '../../../types/maps.consts';
import { Feature, Point } from 'geojson';
import { updateImages } from './images-manager';
import _forEach from 'lodash/forEach';
import _isString from 'lodash/isString';
import _isNil from 'lodash/isNil';
import _defaults from 'lodash/defaults';
import _uniqBy from 'lodash/uniqBy';
import { updatePolylines } from './polylines-manager';
import { fitBoundsByMarkers } from '../mapbox-route-tracker/utils/mapbox-bounds-fit';

type IdsMap = { [key: number]: boolean };
type ClusterData = { shouldCluster: boolean; clusterBelowZoom: number };

export const markersSourceId = 'imageMarkersSource';
export const markersLayerId = 'unclustered-point';
export const homeMarkerId = 'homeMarkerId';
export const clustersCountLayerId = 'cluster-count';
const clustersLayerId = 'clusters';
const homeSourceId = 'homeSourceId';
const homeLayerId = 'homeLayerId';

export const updateMarkers = async (
	markers: MapboxMarkerData[],
	markersMap: Map<IdType, MarkerInstance>,
	imagesMap: Map<ImageUrl, MapboxImage>,
	polylines: PolylineData[],
	map: MapboxMap,
	clusterData?: ClusterData
) => {
	const markersIds = {};
	const imageMarkersAsFeatures: Feature[] = [];
	const uniqueImageUrls: { imageUrl: string; options?: MapboxIconOptions }[] = [];

	_forEach(markers, marker => {
		const { id } = marker;
		markersIds[id] = true;

		if (isMarkerImage(marker)) {
			addMarkerAsImageIcon(map, marker, markersMap, id, imageMarkersAsFeatures, uniqueImageUrls);
		} else {
			addOrUpdateMapboxMarker(marker, markersMap, map);
		}
	});

	await updateImages(
		_uniqBy(uniqueImageUrls, item => {
			return item.imageUrl;
		}),
		imagesMap,
		map
	);

	if (imageMarkersAsFeatures.length) {
		addOrUpdateImageMarkers(imageMarkersAsFeatures, markersMap, map, clusterData);
	}
	updatePolylines(map, polylines);

	removeOldMarkers(markersIds, markersMap, map);
};

export const isMarkerImage = (marker: MapboxMarkerData): boolean => {
	return _isString(marker.icon);
};

const addMarkerAsImageIcon = async (
	map: MapboxMap,
	marker: MapboxMarkerData,
	markersMap: Map<IdType, MarkerInstance>,
	id: IdType,
	imageMarkersAsFeatures: Feature[],
	uniqueImageUrls: { imageUrl: string; options?: MapboxIconOptions }[]
) => {
	const feature = convertImageMarkerToFeature(marker);
	const currentMarker = markersMap.get(id);
	const icon = marker.icon as ImageUrl;

	if (currentMarker?.mapboxMarker) {
		removeMarker(id, markersMap, map);
	}

	const markerInstance = {
		id,
		sourceId: id === homeMarkerId ? homeSourceId : markersSourceId,
		layerId: id === homeMarkerId ? homeLayerId : markersLayerId
	};

	markersMap.set(id, markerInstance);

	const { popup } = marker;

	if (popup) {
		createMapboxPopup(id, popup, markersMap);
	}

	imageMarkersAsFeatures.push(feature);
	uniqueImageUrls.push({ imageUrl: icon, options: marker.iconOptions });
};

const addOrUpdateMapboxMarker = (marker: MapboxMarkerData, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	const { id, location, popup, color } = marker;
	const anchor = marker.anchor || defaultAnchor;
	const { lng, lat } = location;
	const icon = marker.icon as JSX.Element;

	const currentMarker = markersMap.get(id);

	if (currentMarker && currentMarker.mapboxMarker) {
		// @ts-ignore
		currentMarker.mapboxMarker._anchor = anchor;
		currentMarker.mapboxMarker.setLngLat([lng, lat]);

		if (icon) {
			ReactDOM.render(icon, currentMarker.mapboxMarker.getElement());
		}
	} else {
		let iconElement;

		if (icon) {
			iconElement = document.createElement('div');
			ReactDOM.render(icon, iconElement);
		}

		const mapboxMarker = new MapboxMarker(iconElement, { anchor, color });
		mapboxMarker.setLngLat([lng, lat]).addTo(map);

		const markerInstance: MarkerInstance = {
			id,
			mapboxMarker
		};
		markersMap.set(id, markerInstance);
	}

	const { mapboxMarker } = markersMap.get(id);
	const currentPopup = mapboxMarker.getPopup();

	if (popup) {
		const mapboxPopup = createMapboxPopup(id, popup, markersMap);

		if (!currentPopup) {
			mapboxMarker.setPopup(mapboxPopup);
		}
	} else {
		if (currentPopup) {
			currentPopup.remove();
		}
	}
};

const createMapboxPopup = (id: IdType, popup: Popup, markersMap: Map<IdType, MarkerInstance>): MapboxPopup => {
	const { element, anchor } = popup;
	let offset: PointLike;
	let resultPopup: MapboxPopup;

	const currentPopup = markersMap.get(id).mapboxPopup;

	if (popup.offset) {
		const { x, y } = popup.offset;
		offset = [x, y];
	}

	if (currentPopup) {
		// @ts-ignore
		const { options } = currentPopup;
		options.anchor = anchor;
		options.offset = offset;

		resultPopup = currentPopup;
	} else {
		const mapboxPopup = new MapboxPopup({ closeButton: false, offset, anchor });
		markersMap.get(id).mapboxPopup = mapboxPopup;

		resultPopup = mapboxPopup;
	}

	const popupElement = document.createElement('div');
	ReactDOM.render(element, popupElement);
	resultPopup.setDOMContent(popupElement);

	return resultPopup;
};

const convertImageMarkerToFeature = (marker: MapboxMarkerData): Feature => {
	const { id, location, text, color } = marker;
	const { lat, lng } = location;
	const anchor = marker.anchor || defaultAnchor;
	const icon = marker.icon as ImageUrl;
	const textColor = marker.textColor || defaultTextColor;

	const properties = {
		id,
		anchor,
		icon,
		text,
		color,
		textColor
	};

	return {
		type: 'Feature',
		id,
		properties,
		geometry: {
			type: 'Point',
			coordinates: [lng, lat]
		}
	};
};

const addHomeMarker = (map: MapboxMap, homeMarkerAsFeature?: Feature) => {
	if (_isNil(homeMarkerAsFeature)) {
		return;
	}
	if (map.getLayer(homeLayerId)) {
		map.removeLayer(homeLayerId);
	}
	if (map.getSource(homeSourceId)) {
		map.removeSource(homeSourceId);
	}

	const clustersLayer = map.getLayer(clustersLayerId);

	map.addSource(homeSourceId, {
		type: 'geojson',
		data: homeMarkerAsFeature
	});
	map.addLayer(
		{
			id: homeLayerId,
			type: 'symbol',
			source: homeSourceId,
			filter: ['!', ['has', 'point_count']],
			layout: {
				'icon-size': 1,
				'icon-anchor': ['get', 'anchor'],
				'icon-image': ['concat', 'image-', ['get', 'icon']],
				'icon-allow-overlap': true
			}
		},
		clustersLayer?.id
	);
};

const addOrUpdateImageMarkers = (
	markersAsFeatures: Feature[],
	markersMap: Map<IdType, MarkerInstance>,
	map: MapboxMap,
	clusterData?: ClusterData
) => {
	clusterData = _defaults(clusterData, { shouldCluster: false, clusterBelowZoom: 14 });
	const { shouldCluster, clusterBelowZoom } = clusterData;

	const homeMarkerAsFeature = markersAsFeatures.filter(feature => feature.id === homeMarkerId)?.[0];
	const notHomeMarkersAsFeatures = markersAsFeatures.filter(feature => feature.id !== homeMarkerId);
	addHomeMarker(map, homeMarkerAsFeature);

	const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
		type: 'FeatureCollection',
		features: notHomeMarkersAsFeatures
	};

	const currentSource = map.getSource(markersSourceId) as GeoJSONSource;

	if (currentSource) {
		currentSource.setData(data);
	} else {
		map.addSource(markersSourceId, {
			type: 'geojson',
			data,
			cluster: shouldCluster,
			clusterMaxZoom: clusterBelowZoom,
			clusterMinPoints: 5,
			clusterRadius: 50
		});
	}

	if (shouldCluster) {
		addClusterLayers(markersSourceId, map);
	}

	const homeLayer = map.getLayer(homeLayerId);

	if (!map.getLayer(markersLayerId)) {
		map.addLayer(
			{
				id: markersLayerId,
				type: 'symbol',
				source: markersSourceId,
				filter: ['!', ['has', 'point_count']],
				layout: {
					'icon-size': 1,
					'icon-anchor': ['get', 'anchor'],
					'icon-image': ['concat', 'image-', ['get', 'icon']],
					'icon-allow-overlap': true,
					'text-allow-overlap': true,
					'text-font': ['literal', ['Rubik Regular']],
					'text-size': 14,
					'text-field': ['get', 'text'],
					'text-anchor': ['get', 'anchor'],
					'text-offset': [0, -0.8]
				},
				paint: {
					'icon-color': ['get', 'color'],
					'text-color': ['get', 'textColor']
				}
			},
			homeLayer?.id
		);

		map.on('click', markersLayerId, (event: MapLayerMouseEvent) => {
			if (!event) {
				return;
			}

			const { id } = event.features[0].properties;
			const { coordinates } = event.features[0].geometry as Point;

			if (!markersMap.has(id)) {
				const markerInstance = {
					id,
					sourceId: markersSourceId
				};

				markersMap.set(id, markerInstance);
			}

			const { mapboxPopup } = markersMap.get(id);

			if (mapboxPopup) {
				mapboxPopup.setLngLat(coordinates as LngLatLike).addTo(map);
			}
		});
	}
	fitBoundsByMarkers(map, markersAsFeatures);
};

const addClusterLayers = (imagesSourceId: string, map: MapboxMap) => {
	if (!map.getLayer(clustersCountLayerId)) {
		map.addLayer({
			id: clustersCountLayerId,
			type: 'symbol',
			source: imagesSourceId,
			filter: ['has', 'point_count'],
			layout: {
				'text-field': '{point_count_abbreviated}',
				'text-size': 12
			}
		});
	}

	if (!map.getLayer(clustersLayerId)) {
		map.addLayer(
			{
				id: clustersLayerId,
				type: 'circle',
				source: imagesSourceId,
				filter: ['has', 'point_count'],
				paint: {
					'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
					'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
				}
			},
			clustersCountLayerId
		);
	}
};

const removeMarker = (markerId: IdType, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	const markerInstance = markersMap.get(markerId);
	const mapboxMarker = markerInstance.mapboxMarker;
	const mapboxPopup = markerInstance.mapboxPopup;

	if (mapboxMarker) {
		mapboxMarker.remove();
	}

	if (mapboxPopup) {
		mapboxPopup.remove();
	}

	/* contains only homeIcon and on run marker*/
	if (markersMap.size === 2) {
		removeMarkersAndClustersLayers(map);
	}

	markersMap.delete(markerId);
};

//@ts-ignore
const removeOldMarkers = (markersIds: IdsMap, markersMap: Map<IdType, MarkerInstance>, map: MapboxMap) => {
	for (const key of markersMap.keys()) {
		if (!markersIds[key]) {
			removeMarker(key, markersMap, map);
		}
	}
};

const removeMarkersAndClustersLayers = (map: MapboxMap) => {
	if (map.getLayer(markersLayerId)) {
		map.removeLayer(markersLayerId);
	}

	if (map.getLayer(clustersLayerId)) {
		map.removeLayer(clustersLayerId);
	}

	if (map.getLayer(clustersCountLayerId)) {
		map.removeLayer(clustersCountLayerId);
	}

	if (map.getSource(markersSourceId)) {
		map.removeSource(markersSourceId);
	}
};
