import React, { useEffect, useRef, useState } from 'react';
import Input from '../../components/floating-placeholder-input/input';
import Collapse from '../../components/collapse/collapse';
import EditButton, { MODES } from '../../components/edit-button/edit-button';
import noop from 'lodash/noop';

export const FIELDS = {
	cashPart: 'cash-part',
	deliveryFee: 'delivery-fee',
	driverTip: 'driver-tip'
};

export type InputsState = {
	'cash-part': number | '';
	'delivery-fee': number | '';
	'driver-tip': number | '';
};

export interface Translations {
	cashPart: string;
	deliveryFee: string;
	driverTip: string;
}

export interface PaymentProps {
	header: string;
	onSave?: (inputValues: InputsState) => Promise<any>;
	onCancel?: (...args: any[]) => void;
	onEdit?: (...args: any[]) => void;
	defaultValues?: InputsState;
	translations: Translations;
}

const Payment: React.FC<PaymentProps> = ({ header, onSave, onCancel, onEdit, defaultValues, translations }) => {
	const firstInput = useRef(null);
	const [mode, setMode] = useState(MODES.VIEW);
	const [isOpen, setIsOpen] = useState(true);
	const [inputValues, setInputValues] = useState<InputsState>(defaultValues);

	const handleChange = event => {
		setInputValues({
			...inputValues,
			[event.target.name]: event.target.value
		});
	};
	const handleClick = (event, newMode) => {
		event.stopPropagation();
		setMode(newMode);
		if (newMode === MODES.EDIT) {
			setIsOpen(true);
		}
	};
	const handleCancel = event => {
		handleClick(event, MODES.VIEW);
		onCancel(inputValues);
		setInputValues(defaultValues);
	};
	const handleSave = async event => {
		handleClick(event, MODES.VIEW);
		try {
			await onSave(inputValues);
		} catch (e) {
			setInputValues(defaultValues);
		}
	};
	const handleEdit = event => {
		handleClick(event, MODES.EDIT);
		onEdit();
	};

	useEffect(() => {
		if (mode === MODES.EDIT) {
			firstInput.current.focus();
		}
	}, [mode]);

	return (
		<div className="payment-container">
			<Collapse
				key="payment"
				isOpen={isOpen}
				onChange={open => setIsOpen(open)}
				header={header}
				extra={<EditButton onSave={handleSave} onEdit={handleEdit} onCancel={handleCancel} mode={mode} />}
			>
				<div className="payment">
					{Object.keys(FIELDS).map((fieldKey, index) => {
						return (
							<div className="field" key={FIELDS[fieldKey]}>
								<div className="input-container">
									<Input
										name={FIELDS[fieldKey]}
										placeholder={translations[fieldKey]}
										onChange={handleChange}
										value={inputValues[FIELDS[fieldKey]]}
										type="number"
										className={FIELDS[fieldKey]}
										isDisabled={mode === MODES.VIEW}
										ref={index === 0 ? firstInput : null}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</Collapse>
		</div>
	);
};

Payment.defaultProps = {
	onCancel: noop,
	onSave: () => Promise.resolve(),
	onEdit: noop,
	defaultValues: {
		'cash-part': '',
		'delivery-fee': '',
		'driver-tip': ''
	}
};

export default Payment;
