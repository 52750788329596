import { useEffect } from 'react';
import { updateMarkers } from '../../../components/mapbox/utils/markers-manager';
import { Map as MapboxMap } from 'mapbox-gl';
import { IdType, ImageUrl, PolylineData } from '../../../types/maps.consts';
import { MapboxImage, MapboxMarkerData, MarkerInstance } from '../../../components/mapbox/mapbox';

interface MapboxDataProps {
	map: MapboxMap;
	markersMap: Map<IdType, MarkerInstance>;
	imagesMap: Map<ImageUrl, MapboxImage>;
	markers?: MapboxMarkerData[] | Promise<MapboxMarkerData[]>;
	polylines?: PolylineData[];
	shouldCluster?: boolean;
	clusterBelowZoom?: number;
}
export const useMapboxLoadData = (props: MapboxDataProps) => {
	const { map, markersMap, imagesMap, markers = [], polylines = [], shouldCluster, clusterBelowZoom } = props;

	useEffect(() => {
		if (!map) {
			return;
		}

		const handleMarkersChange = async () => {
			let markersToUpdates = markers;

			if (markersToUpdates instanceof Promise) {
				markersToUpdates = await markersToUpdates;
			}

			await updateMarkers(markersToUpdates, markersMap, imagesMap, polylines, map, {
				shouldCluster,
				clusterBelowZoom
			});
		};

		handleMarkersChange();
	}, [map, markers, polylines, shouldCluster, clusterBelowZoom]);
};
