import React, { Suspense } from 'react';
import { MomentInput } from 'moment';
const FormattedTimeWindow = React.lazy(() => import('./formatted-time-window'));
const FormatDateTimezone = React.lazy(() => import('./format-date-timezone'));
interface Props {
	date: MomentInput;
	format?: string;
	invalidDateText?: string;
	className?: string;
	timezone?: string;
}
export const getTimeFormat = (hourFormat = 'LT') => hourFormat;
export const getDateFormat = (hourFormat = 'LT') => `MMM DD, YYYY, ${hourFormat}`;

export const getFormattedTimeWindow = (
	firstDate: MomentInput | string,
	secondDate: MomentInput | string,
	hourFormat: string,
	timezone?: string
) => {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<FormattedTimeWindow
				firstDate={firstDate}
				secondDate={secondDate}
				hourFormat={hourFormat}
				timezone={timezone}
			/>
		</Suspense>
	);
};

const DateDisplay: React.FC<Props> = ({ date, format, invalidDateText = '', className, timezone }) => {
	return (
		<span className={className}>
			{date ? (
				<Suspense fallback={<div>Loading...</div>}>
					<FormatDateTimezone date={date} format={format} timezone={timezone} />
				</Suspense>
			) : (
				invalidDateText
			)}
		</span>
	);
};

export default DateDisplay;
