import React, { memo } from 'react';

import './multiselect.scss';

interface Props {
	handleClick: () => void;
	isAllSelected: boolean;
	clearSelectionText: string;
	selectAllText: string;
}

const SelectAll = ({ isAllSelected, handleClick, clearSelectionText, selectAllText }: Props) => {
	return (
		<div className="select-all-wrapper">
			<span className="select-all" onClick={handleClick}>
				{isAllSelected ? clearSelectionText : selectAllText}
			</span>
		</div>
	);
};

export default memo(SelectAll);
