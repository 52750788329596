import * as React from 'react';
import { Popover } from 'antd';

interface Props {
	children?: React.ReactNode;
	content?: string | React.ReactNode;
	title?: string | React.ReactNode;
	trigger?: 'hover' | 'focus' | 'click' | 'contextMenu';
	overlayClassName?: string;
	mouseEnterDelay?: number;
	placement?:
		| 'top'
		| 'left'
		| 'right'
		| 'bottom'
		| 'topLeft'
		| 'topRight'
		| 'bottomLeft'
		| 'bottomRight'
		| 'leftTop'
		| 'leftBottom'
		| 'rightTop'
		| 'rightBottom';
}

const PopOver = (props: Props) => {
	return (
		<Popover
			content={props.content}
			title={props.title}
			trigger={props.trigger}
			placement={props.placement}
			overlayClassName={props.overlayClassName}
			mouseEnterDelay={props.mouseEnterDelay}
		>
			{props.children}
		</Popover>
	);
};

PopOver.defaultProps = {
	placement: 'top',
	trigger: 'hover',
	title: ''
};

export default PopOver;
