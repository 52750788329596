import Row from '../../../components/row/row';
import Col from '../../../components/col/col';
import TaskNotes from '../../../components/task-notes/task-notes';
import { TabPane } from '../../../components/tabs/tabs';
import React from 'react';

const getTaskNoteTab = ({
	translations,
	taskNotes,
	dateFormat,
	wayPointDataID,
	onNoteAdded,
	mapData,
	tabComponent,
	editable = true,
	timezone
}) => {
	return (
		<TabPane tab={tabComponent} key={`waypoint-${wayPointDataID}-notes`}>
			<Row gutter={24}>
				<Col span={24}>
					<TaskNotes
						taskNotes={taskNotes}
						onNoteAdded={onNoteAdded}
						dateFormat={dateFormat}
						mapData={mapData}
						translations={translations}
						editable={editable}
						timezone={timezone}
					/>
				</Col>
			</Row>
		</TabPane>
	);
};
export default getTaskNoteTab;
