import * as React from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';

const { RangePicker } = DatePicker;

const DEFAULT_FORMAT = 'YYYY-MM-DD';

type Props = RangePickerProps;

const RangeCalendar: React.FunctionComponent<Props> = (props: Props) => {
	return <RangePicker allowClear={false} format={DEFAULT_FORMAT} {...props} />;
};

export default RangeCalendar;
