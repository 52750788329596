import * as React from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';
import { AutoCompleteProps as AntdAutoCompleteProps } from 'antd/lib/auto-complete';

export interface AutoCompleteProps extends AntdAutoCompleteProps {}

const AutoComplete: React.FC<AutoCompleteProps> = props => {
	return <AntdAutoComplete {...props} />;
};

export default AutoComplete;
