import React from 'react';
import Row from '../row/row';
import Col from '../col/col';
import DateDisplay, { getDateFormat, getFormattedTimeWindow, getTimeFormat } from '../date-display/date-display';
import Timezone from '../timezone/timezone';

export interface Translations {
	scheduledArrival: string;
	scheduledFor: string;
	timeWindow: string;
	eta: string;
	etl: string;
	arrived: string;
	left: string;
}

interface WayPointTimesProps {
	scheduledAt?: string;
	eta?: string;
	hourFormat: string;
	noLaterThan?: string;
	noEarlierThan?: string;
	etl?: string;
	checkinTime?: string;
	checkoutTime?: string;
	timeZone?: string;
	translations: Translations;
	useTeamTimezone?: boolean;
	scheduledArrival?: string;
}

const INVALID_DATE = '--:--';

const WayPointTimes: React.FC<WayPointTimesProps> = ({
	scheduledAt,
	eta,
	noLaterThan,
	noEarlierThan,
	etl,
	hourFormat,
	checkinTime,
	checkoutTime,
	timeZone,
	translations,
	useTeamTimezone,
	scheduledArrival
}: WayPointTimesProps) => {
	const timezoneForDisplay = useTeamTimezone && timeZone;

	const getTimeColumn = (title?: string, content?: React.ReactNode, dataTestId?: string) => (
		<Col span={6} className="waypoint-time-column-container" data-test-id={dataTestId ? dataTestId : ''}>
			<span>{title}</span>
			<div className="waypoint-time-content">{content}</div>
			<Timezone timezone={timeZone} />
		</Col>
	);

	return (
		<div className="waypoint-times-container">
			<Row gutter={14}>
				{getTimeColumn(
					translations.scheduledFor,
					<DateDisplay
						invalidDateText={INVALID_DATE}
						date={scheduledAt}
						format={getDateFormat(hourFormat)}
						timezone={timezoneForDisplay}
					/>,
					'scheduled-for'
				)}
				{getTimeColumn(
					checkinTime ? translations.arrived : translations.eta,
					<DateDisplay
						invalidDateText={INVALID_DATE}
						date={checkinTime || eta}
						format={getTimeFormat(hourFormat)}
						timezone={timezoneForDisplay}
					/>,
					'checkin-time'
				)}
				{getTimeColumn(
					translations.timeWindow,
					<div>{getFormattedTimeWindow(noEarlierThan, noLaterThan, hourFormat, timezoneForDisplay)}</div>,
					'time-window'
				)}
				{getTimeColumn(
					checkoutTime ? translations.left : translations.etl,
					<DateDisplay
						invalidDateText={INVALID_DATE}
						date={checkoutTime || etl}
						format={getTimeFormat(hourFormat)}
						timezone={timezoneForDisplay}
					/>,
					'checkout-time'
				)}
				{scheduledArrival &&
					getTimeColumn(
						translations.scheduledArrival,
						<DateDisplay
							invalidDateText={INVALID_DATE}
							date={scheduledArrival}
							format={getDateFormat(hourFormat)}
							timezone={timezoneForDisplay}
						/>,
						'scheduled-arrival'
					)}
			</Row>
		</div>
	);
};

export default WayPointTimes;
