import React, { memo } from 'react';
import { Select as AntdSelect } from 'antd';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './select.scss';

const { Option } = AntdSelect;

export interface SelectProps {
	value: any;
	placeholder?: string;
	onChange: (value) => void;
	options: any[];
}

const Select = ({ value, placeholder, options, onChange }: SelectProps) => {
	return (
		<div className="filter-item">
			<AntdSelect
				placeholder={placeholder}
				value={value}
				suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
				onChange={onChange}
			>
				{options.map(({ value, label }) => (
					<Option className="select-option" value={value}>
						{label}
					</Option>
				))}
			</AntdSelect>
		</div>
	);
};

export default memo(Select);
