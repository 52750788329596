import * as React from 'react';
import { Form } from 'antd';
import { Col } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';
import _noop from 'lodash/noop';
import _isFunction from 'lodash/isFunction';

const FormItem = Form.Item;

const REACT_COLUMNS_NUM: number = 24;

interface IFormWrapperProps extends FormProps {
	className?: string;
	onSubmit?: (values: any) => void;
	children: JSX.Element[];
	numberOfColumns?: number;
	showSubmitButton?: boolean;
	cancelButton?: JSX.Element;
	submitButton?: JSX.Element;
	onResetForm?: () => void;
	layout?: any;
	resetOnErr?: boolean;
}

interface FormChildrenProps {
	id: string;
	formItemsProps?: FormItemProps;
}

export const FormChildren: React.FunctionComponent<FormChildrenProps> = props => {
	return null;
};

const formItemLayout = formLayout =>
	formLayout === 'horizontal'
		? {
				labelCol: { span: 12 },
				wrapperCol: { span: 10 }
		  }
		: null;

class FormWrapper extends React.Component<IFormWrapperProps> {
	static defaultProps = {
		numberOfColumns: 2,
		onResetForm: _noop,
		showSubmitButton: true,
		showCancelButton: true,
		layout: 'vertical'
	};

	handleSubmit = (values: any) => {
		const { onSubmit } = this.props;

		if (_isFunction(onSubmit)) {
			onSubmit(values);
		}
	};

	handleFailedSubmit = ({ errorFields }) => {
		this.props.form?.scrollToField(errorFields[0].name);

		if (this.props.resetOnErr) {
			this.resetForm();
		}
	};

	resetForm = (): void => {
		this.props.form?.resetFields();

		const { onResetForm } = this.props;
		onResetForm && onResetForm();
	};

	render() {
		const {
			className,
			layout,
			children,
			numberOfColumns,
			submitButton,
			cancelButton,
			initialValues,
			form
		} = this.props;

		return (
			<Form
				form={form}
				onFinish={this.handleSubmit}
				onFinishFailed={this.handleFailedSubmit}
				className={className}
				layout={layout}
				initialValues={initialValues}
			>
				{children.map(item =>
					item.type === FormChildren ? (
						<Col span={REACT_COLUMNS_NUM / numberOfColumns} key={item.props.id}>
							<FormItem {...item.props.formItemsProps} {...formItemLayout(layout)}>
								{item.props.children}
							</FormItem>
						</Col>
					) : (
						item
					)
				)}
				{submitButton}
				{cancelButton}
			</Form>
		);
	}
}

export default FormWrapper;
