import React from 'react';
import { get as _get } from 'lodash';
import { Task, TaskTypeId } from '@bringg/types';
import Label from '../label/label';

export interface Translations {
	collectText: string;
	deliverText: string;
}

interface LinkedTasksProps {
	task: Task;
	linkedTask: Task;
	translations: Translations;
}

const getLabel = (task: Task, translations: Translations) => {
	const taskTypeId: TaskTypeId = _get(task, 'task_type_id');

	if (taskTypeId === TaskTypeId.PICKUP) {
		return <Label text={translations.collectText} size="small" className="orange" />;
	}

	if (taskTypeId === TaskTypeId.DELIVERY) {
		return <Label text={translations.deliverText} size="small" className="green" />;
	}
};

const LinkedTasks: React.FC<LinkedTasksProps> = ({ task, linkedTask, translations }) => {
	return (
		<div className="linked-tasks-container">
			<div className="linked-task">
				<a href="#" className="linked-task-link">
					#{task.id}
				</a>
				{getLabel(task, translations)}
			</div>

			<div className="linked-task">
				<a href="#" className="linked-task-link">
					#{_get(linkedTask, 'id')}
				</a>
				{getLabel(linkedTask, translations)}
			</div>
		</div>
	);
};

export default LinkedTasks;
