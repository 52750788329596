import React, { useState, useEffect } from 'react';
import TaskInventory, { Translations as TaskInventoryTranslations } from '../task-inventory/task-inventory';
import { Inventory, TaskInventory as TaskInventoryType, WayPoint, Task } from '@bringg/types';
import { Collapse, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomModal from '../custom-modal/custom-modal';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import TaskInventoryForm from '../task-inventory/task-inventory-form/task-inventory-form';
import { TaskInventoriesFlagsAccess } from '../../features/waypoint-card/wapoint-card';
import ExpandCollapseButton from '../expand-collapse-button/expand-collapse-button';
import TaskInventoriesSearch from '../task-inventories-search/task-inventories-search';
import {
	getAllTaskInventoryChildren,
	getParentTaskInventoryIds,
	getAllTaskInventory
} from './utils/task-inventories-utils';
const { Panel } = Collapse;

export interface Translations extends TaskInventoryTranslations {}

interface AllTaskInventories {
	[key: number]: TaskInventoryType;
}
interface TaskInventoriesProps {
	taskInventories: TaskInventoryType[];
	translations: Translations;
	onQuantityChange?: (massQuantityUpdate: TaskInventoryType[]) => void;
	onInventoriesChange?: (inventories: Inventory[]) => void;
	editable?: boolean;
	depth?: number;
	bordered?: boolean;
	flagsAccess: TaskInventoriesFlagsAccess;
	wayPointData?: WayPoint;
	task?: Task;
}

const VerticalLine = () => <span className="vertical-line"> </span>;

const TaskInventories: React.FC<TaskInventoriesProps> = ({
	taskInventories,
	onQuantityChange,
	onInventoriesChange,
	translations,
	editable = true,
	depth = 0,
	bordered = true,
	flagsAccess,
	wayPointData,
	task
}: TaskInventoriesProps) => {
	const [taskInventoryToEdit, setTaskInventoryToEdit] = useState<TaskInventoryType>();
	const { isOpen: isModalOpen, open: openModal, close: closeModal } = CustomModal.useModalState(false);

	const [expanded, setExpanded] = useState(false);
	const [expandKey, setExpandKey] = useState({ activeKey: [] });
	const [filteredInventories, setFilteredInventories] = useState(taskInventories);

	const [shownFields, setShownFields] = useState({
		scan_string: false,
		weight: false,
		dimensions: false,
		price: false,
		note: false,
		scheduled_arrival: false
	});

	useEffect(() => {
		handleShownFields();
	}, []);

	useEffect(() => {
		const taskInventoriesIds: string[] =
			expanded && !expandKey.activeKey.length ? getParentTaskInventoryIds(taskInventories) : [];
		changeActiveKey(taskInventoriesIds);
	}, [expanded]);

	const toggleExpandCollapse = () => {
		setExpanded(prevState => !prevState);
	};

	const changeActiveKey = keys => {
		//get children of the item that is being closed for handling items with open children when closing parent
		const closedItemChildren = getClosedItemChildren(expandKey.activeKey, keys);

		if (closedItemChildren.length) {
			keys = keys.filter(item => !closedItemChildren.includes(item));
		}
		setExpandKey({
			activeKey: keys
		});
		if (!keys.length && expanded) {
			setExpanded(false);
		}
	};

	const allTaskInventories: AllTaskInventories = getAllTaskInventory(task, wayPointData.id);

	const handleShownFields = () => {
		for (const key in allTaskInventories) {
			for (const field in shownFields) {
				const showDimensions =
					field === 'dimensions' &&
					allTaskInventories[key]['width'] &&
					allTaskInventories[key]['height'] &&
					allTaskInventories[key]['length'];
				if (
					showDimensions ||
					(allTaskInventories[key][field] && allTaskInventories[key][field]?.length !== 0)
				) {
					setShownFields(prevState => {
						return {
							...prevState,
							[field]: true
						};
					});
				}
			}
		}
	};

	//for handling closing children when parent gets closed
	const getClosedItemChildren = (keys, key: string | string[]) => {
		const closedItem = keys.find(item => !key.includes(item));
		const taskInventory = taskInventories.filter(item => item.id === parseInt(closedItem))[0];
		const closedItemChildren = taskInventory ? getParentTaskInventoryIds(taskInventory.inventories) : [];
		return closedItemChildren;
	};

	const onEditCLick = (event, taskInventory) => {
		event.stopPropagation();
		setTaskInventoryToEdit(taskInventory);
		openModal();
	};

	const hideForm = () => {
		closeModal();
	};

	const showEditButton: boolean =
		editable &&
		(flagsAccess.enable_dispatcher_edit_task_inventory_quantity || flagsAccess.enable_dimension_editing_in_ui);

	const getTaskInventoryCard = (taskInventory: TaskInventoryType) => {
		return (
			<Card
				className="task-inventories-card"
				bordered={bordered}
				key={taskInventory.id.toString()}
				extra={
					showEditButton && (
						<FontAwesomeIcon
							className="pencil"
							icon={faPencilAlt}
							onClick={event => onEditCLick(event, taskInventory)}
						/>
					)
				}
			>
				<TaskInventory
					parentTaskInventory={taskInventory}
					translations={translations}
					editable={editable}
					shownFields={shownFields}
					wayPointCheckinTime={wayPointData?.checkin_time}
					getAllTaskInventoryChildren={getAllTaskInventoryChildren}
				/>
			</Card>
		);
	};

	const getTaskInventoryCollapse = (taskInventory: TaskInventoryType, depth: number) => {
		return (
			<Collapse
				key={taskInventory.id.toString()}
				style={{ marginLeft: `${depth * 3}%` }}
				className="site-collapse-custom-collapse task-inventories-collapse"
				onChange={changeActiveKey}
				activeKey={expandKey?.activeKey}
			>
				<Panel
					key={taskInventory.id.toString()}
					className="site-collapse-custom-panel"
					header={
						<TaskInventory
							parentTaskInventory={taskInventory}
							translations={translations}
							editable={editable}
							shownFields={shownFields}
							wayPointCheckinTime={wayPointData?.checkin_time}
							getAllTaskInventoryChildren={getAllTaskInventoryChildren}
						/>
					}
					extra={
						depth === 0 &&
						showEditButton && (
							<FontAwesomeIcon
								className="pencil"
								icon={faPencilAlt}
								onClick={event => onEditCLick(event, taskInventory)}
							/>
						)
					}
				>
					{getTaskInventoryChildren(taskInventory.inventories, 1)}
				</Panel>
			</Collapse>
		);
	};

	const getTaskInventoryChildren = (taskInventoryChildren: TaskInventoryType[], depth) => {
		return taskInventoryChildren.map(taskInventoryChild => {
			if (taskInventoryChild.inventories?.length) {
				return getTaskInventoryCollapse(taskInventoryChild, depth);
			}
			return (
				<TaskInventory
					depth={depth}
					key={taskInventoryChild.id.toString()}
					parentTaskInventory={taskInventoryChild}
					translations={translations}
					editable={editable}
					shownFields={shownFields}
					wayPointCheckinTime={wayPointData?.checkin_time}
					getAllTaskInventoryChildren={getAllTaskInventoryChildren}
				/>
			);
		});
	};

	const taskInventoriesList = filteredInventories.map(taskInventory => {
		if (taskInventory.inventories?.length) {
			return getTaskInventoryCollapse(taskInventory, 0);
		}
		return getTaskInventoryCard(taskInventory);
	});
	const setFilteredInventoriesFromSearch = (taskInventories: TaskInventoryType[]) => {
		setFilteredInventories(taskInventories);
	};
	return (
		<div className="task-inventories-container">
			<div className="task-inventories-actions">
				<ExpandCollapseButton
					onClick={toggleExpandCollapse}
					isExpanded={!!expandKey.activeKey.length}
					translations={translations}
				/>
				<VerticalLine />
				<TaskInventoriesSearch
					taskInventories={taskInventories}
					allTaskInventories={allTaskInventories}
					getAllTaskInventoryChildren={getAllTaskInventoryChildren}
					setFilteredInventories={setFilteredInventoriesFromSearch}
					changeActiveKey={changeActiveKey}
					translations={translations}
				/>
			</div>
			{taskInventoriesList}
			<CustomModal className="task-inventory-edit-modal" isOpen={isModalOpen} onClickOutside={hideForm}>
				<h1>{translations.editItem}</h1>
				<div>
					<TaskInventoryForm
						taskInventory={taskInventoryToEdit}
						parentTaskInventory={taskInventoryToEdit}
						taskInventories={taskInventories}
						onQuantityChange={onQuantityChange}
						onInventoriesChange={onInventoriesChange}
						onSave={hideForm}
						onCancel={hideForm}
						translations={translations}
						flagsAccess={flagsAccess}
					/>
				</div>
			</CustomModal>
		</div>
	);
};

export default TaskInventories;
