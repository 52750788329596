import { Map as MapboxMap } from 'mapbox-gl';
import { IdType } from '../../../../types/maps.consts';
import { markersLayerId } from '../../utils/markers-manager';

const ROUTE_STROKE_OPACITY = 1;
const ROUTE_STROKE_WIDTH = 4;

export const drawRoute = (
	map: MapboxMap,
	route: { id: IdType; locations: Bringg.Location[] },
	color: string,
	strokeOpacity?: number,
	strokeWidth?: number
) => {
	if (!route.locations) {
		return;
	}
	const id = getRouteSourceAndLayerId(route.id);
	removeRoute(map, route.id);
	map.addSource(id, {
		type: 'geojson',
		data: {
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'LineString',
				coordinates: route.locations.map(item => {
					return [item.lng, item.lat];
				})
			}
		}
	});

	const markersLayer = map.getLayer(markersLayerId);

	map.addLayer(
		{
			id: id,
			type: 'line',
			source: id,
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			},
			paint: {
				'line-color': color,
				'line-width': strokeWidth ?? ROUTE_STROKE_WIDTH,
				'line-opacity': strokeOpacity ?? ROUTE_STROKE_OPACITY
			}
		},
		markersLayer?.id
	);
};

const getRouteSourceAndLayerId = (routeId: IdType) => `route ${routeId}`;

export const removeRoute = (map, routeId: IdType) => {
	const id = getRouteSourceAndLayerId(routeId);

	if (map.getLayer(id)) {
		map.removeLayer(id);
	}
	if (map.getSource(id)) {
		map.removeSource(id);
	}
};
