import * as React from 'react';
import { Select } from 'antd';
import { OptionProps, SelectProps } from 'antd/lib/select';

export interface MultiSelectOption extends OptionProps {
	key?: number | string;
}

export interface MultiSelectProps extends Omit<SelectProps<any>, 'options'> {
	onChange?: (value: any) => void;
	options: Partial<MultiSelectOption>[];
}

const MultiSelect: React.FC<MultiSelectProps> = props => {
	const { options, mode, ...restOfProps } = props;

	return (
		<div className="select-wrapper">
			<Select {...restOfProps} optionFilterProp="children" mode={mode || 'multiple'}>
				{options.map((option, index) => (
					<Select.Option key={option.key || index.toString()} value={option.value}>
						{option.children || option.value}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

export default MultiSelect;
