import DateDisplay from '../date-display/date-display';
import React from 'react';
import { Task, TaskStatus as TaskStatusEnum } from '@bringg/types';
import { getColorForTaskStatus, getStatusTitle } from './task-status.consts';
import _noop from 'lodash/noop';
import _isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import TooltipWrapper from '../tooltip/tooltip';

export interface Translations {
	notAssigned: string;
	assigned: string;
	onTheWay: string;
	checkedIn: string;
	done: string;
	late: string;
	accepted: string;
	cancelled: string;
	rejected: string;
	unacknowledged: string;
	pending: string;
}

interface TaskStatusProps {
	task: Task;
	dateFormat: string;
	translations: Translations;
	timezone?: string;
	onClick?: () => void;
	hoverText?: string;
}

const TaskStatus: React.FC<TaskStatusProps> = ({ task, dateFormat, translations, timezone, onClick, hoverText }) => {
	const isClickable = _isFunction(onClick);
	return (
		<TooltipWrapper title={hoverText} placement="topLeft">
			<div
				className={classNames('task-status-container', { 'status-clickable': isClickable })}
				onClick={isClickable ? onClick : _noop}
			>
				<div className="task-status-header">
					<div className={`task-status task-status-${getColorForTaskStatus(task.status)}`}></div>

					<div>{getStatusTitle(translations, task.status)}</div>

					<div className="task-status-scheduled-time">
						<DateDisplay date={task.scheduled_at} format={dateFormat} timezone={timezone} />
					</div>
				</div>
				{task.status === TaskStatusEnum.Cancelled ? (
					<div className="task-status-cancelled">{task.task_cancellation_reason}</div>
				) : (
					''
				)}
			</div>
		</TooltipWrapper>
	);
};

export default TaskStatus;
