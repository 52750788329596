import React, { useRef, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';
import classNames from 'classnames';

interface CSSVariables {
	'--ripple-color'?: string;
}

interface PureButtonProps {
	children: string | React.ReactNode;
	name?: string;
	className?: string;
	style?: React.CSSProperties & CSSVariables;
	onClick?: (event?: React.MouseEvent) => void;
}

interface IRippleProps {
	x: number;
	y: number;
	radius: number;
	onAnimationEnd: () => void;
}

interface IRipple {
	x: number;
	y: number;
	radius: number;
	key: string;
}

const getRippleFromEvent = (event: React.MouseEvent, parent: HTMLElement): IRipple => {
	const { x: parentX, y: parentY } = parent.getBoundingClientRect();
	const x = event.clientX - parentX;
	const y = event.clientY - parentY;
	const width = parent.offsetWidth;
	const height = parent.offsetHeight;
	const radius = Math.max(width, height);
	return {
		x,
		y,
		radius,
		key: uniqueId()
	};
};

const Ripple: React.FC<IRippleProps> = ({ x, y, radius, onAnimationEnd }) => {
	const style = {
		left: x - radius,
		top: y - radius,
		width: radius * 2,
		height: radius * 2
	};
	return <span className="ripple" style={style} onAnimationEnd={onAnimationEnd} />;
};

const PureButton: React.FC<PureButtonProps> = ({ children, className, style, onClick: clientOnClick, name }) => {
	const ref = useRef<HTMLButtonElement>(null);
	const [ripples, setRipples] = useState<IRipple[]>([]);

	const onClick = event => {
		const ripple = getRippleFromEvent(event, ref.current);
		setRipples([...ripples, ripple]);
		clientOnClick(event);
	};

	const clearRipple = () => {
		setRipples(ripples.slice(1));
	};

	return (
		<button ref={ref} className={classNames('pure-button', className)} style={style} onClick={onClick} name={name}>
			<span className="text">{children}</span>
			{ripples.map(ripple => (
				<Ripple {...ripple} onAnimationEnd={clearRipple} />
			))}
		</button>
	);
};

PureButton.defaultProps = {
	onClick: noop
};

export default PureButton;
