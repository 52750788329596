import React from 'react';
import { Collapse, CustomTag } from '../../components';
import { Driver } from '@bringg/types';
import RateWrapper from '../../components/rate/rate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';
import BatteryIcon from '../../components/battery-icon/battery-icon';
import _noop from 'lodash/noop';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';

export interface Translations {
	driver: string;
	message: string;
	locate: string;
	unassignDriver: string;
	driverOnShift: string;
	driverOffShift: string;
	driverOnBreak: string;
}

export enum DriverAction {
	MESSAGE,
	LOCATE,
	UNASSIGN
}

interface Props {
	driver?: Driver;
	onDriverAction?: (driverAction: DriverAction) => void;
	noDriverContent?: JSX.Element;
	translations: Translations;
	onDriverClick?: () => void;
	fleetName?: string;
	driverStatus?: DriverStatus;
}

export enum DriverStatus {
	OnShift = 'on-shift',
	OffShift = 'off-shift',
	OnBreak = 'on-break'
}
const DriverAssignment: React.FC<Props> = ({
	driver = {},
	onDriverAction = _noop,
	noDriverContent = null,
	translations,
	onDriverClick = _noop,
	fleetName,
	driverStatus
}) => {
	const { id, name, job_description, average_rating, profile_image, phone, battery } = driver;

	const handleDriverAction = (driverAction: DriverAction) => () => onDriverAction(driverAction);

	const driverStatusToText = {
		[DriverStatus.OnShift]: translations.driverOnShift,
		[DriverStatus.OffShift]: translations.driverOffShift,
		[DriverStatus.OnBreak]: translations.driverOnBreak
	};

	let header = translations.driver;

	if (name) {
		header += ` (${name})`;
	}

	return (
		<Collapse key="driver-assignment" header={header}>
			{id ? (
				<div className="driver-assignment-container">
					<div className="driver-assignment-header">
						<div>
							<div className="driver-assignment-name" onClick={onDriverClick}>
								{name}
							</div>

							{fleetName && <CustomTag className="fleet-tag">{fleetName}</CustomTag>}

							<div className="driver-assignment-job-description">{job_description}</div>

							<RateWrapper disabled={true} allowHalf={true} value={average_rating} />

							{phone && (
								<div className="driver-assignment-phone">
									<FontAwesomeIcon icon={faPhone} />
									<span>{phone}</span>
								</div>
							)}
						</div>

						<div className="driver-assignment-profile">
							<img
								className="driver-assignment-avatar"
								onClick={onDriverClick}
								src={profile_image}
								alt="driver-avatar"
							/>
							<div className={`driver-assignment-status driver-assignment-status-${driverStatus}`}>
								<span className="driver-assignment-status-text">
									{driverStatusToText[driverStatus]}
								</span>
							</div>
						</div>
					</div>

					<div className="driver-assignment-actions">
						<div className="driver-assignment-action" onClick={handleDriverAction(DriverAction.MESSAGE)}>
							<FontAwesomeIcon icon={faComment} />
							<span>{translations.message}</span>
						</div>

						<div className="driver-assignment-action" onClick={handleDriverAction(DriverAction.LOCATE)}>
							<FontAwesomeIcon icon={faMapMarker} />
							<span>{translations.locate}</span>
						</div>

						{battery && (
							<div className="driver-assignment-battery">
								<BatteryIcon batteryPercentage={battery} className="driver-assignment-battery-icon" />
								{battery}%
							</div>
						)}
					</div>

					<div className="driver-assignment-unassign" onClick={handleDriverAction(DriverAction.UNASSIGN)}>
						<FontAwesomeIcon icon={faTimes} />
						{translations.unassignDriver}
					</div>
				</div>
			) : (
				<>{noDriverContent}</>
			)}
		</Collapse>
	);
};

export default DriverAssignment;
