import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Label from '../label/label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons/faBarcodeScan';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import { faRuler } from '@fortawesome/pro-light-svg-icons/faRuler';
import { faWeight } from '@fortawesome/pro-light-svg-icons/faWeight';
import { faStickyNote } from '@fortawesome/pro-light-svg-icons/faStickyNote';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { TaskInventory as TaskInventoryType, datetime } from '@bringg/types';
import { get as _get, isNil as _isNil, sumBy as _sumBy } from 'lodash';
import { TASK_INVENTORY_CONSTANTS } from './task-inventory-form/task-inventory-form';
import classNames from 'classnames';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { UseFormMethods } from 'react-hook-form';
import TooltipWrapper from '../tooltip/tooltip';
import { DateDisplay } from '../index';
import { getDateFormat } from '../date-display/date-display';
import { useTimezone } from '../../features/waypoint-card/hooks/use-timezone';
import { faScanner } from '@fortawesome/pro-solid-svg-icons/faScanner';

export interface Translations {
	collectText: string;
	collectedText: string;
	deliverText: string;
	deliveredText: string;
	rejectedText: string;
	editItem: string;
	quantity: string;
	trashItem: string;
	width: string;
	height: string;
	inventoryLength: string;
	save: string;
	cancel: string;
	dimensions: string;
	scanCode: string;
	weight: string;
	price: string;
	note: string;
	itemArrival: string;
	searchPlaceholder: string;
	collapseAll: string;
	expandAll: string;
	items: string;
}

interface TaskInventoryProps {
	parentTaskInventory: TaskInventoryType;
	depth?: number;
	translations: Translations;
	isInEditMode?: boolean;
	form?: UseFormMethods;
	editable?: boolean;
	shownFields?: Object;
	wayPointCheckinTime?: datetime;
	getAllTaskInventoryChildren?: (
		inventory: TaskInventoryType,
		inventories: TaskInventoryType[]
	) => TaskInventoryType[];
}

enum TaskInventoryFields {
	ScanString = 'scan_string',
	Dimensions = 'dimensions',
	Weight = 'weight',
	Price = 'price',
	Note = 'note',
	ScheduledArrival = 'scheduled_arrival'
}

const MAX_CHARACTERS: number = 20;
const tryLimitLengthString = (stringToLimit: string) => {
	if (stringToLimit?.length > MAX_CHARACTERS) {
		return `${stringToLimit.slice(0, 20)}...`;
	}
	return stringToLimit;
};
const getNumericFieldAsString = (object: any, path: string): string => {
	return _get(object, path) || 'N/A';
};

const getTaskInventoryDimensions = (taskInventory: TaskInventoryType): string => {
	const width = getNumericFieldAsString(taskInventory, 'width');
	const height = getNumericFieldAsString(taskInventory, 'height');
	const length = getNumericFieldAsString(taskInventory, 'length');

	if (_isNil(width) || _isNil(height) || _isNil(length)) {
		return;
	}

	return `${width} x ${height} x ${length}`;
};
const VerticalLine = () => <span className="vertical-line"> </span>;
const TOOLTIP_COLOR = '#333333';
const SCHEDULED_ARRIVAL_FORMAT: string = 'MMM D hA';

const TaskInventory: React.FC<TaskInventoryProps> = ({
	parentTaskInventory,
	isInEditMode = false,
	form,
	translations,
	editable = true,
	depth = 0,
	shownFields,
	wayPointCheckinTime,
	getAllTaskInventoryChildren
}) => {
	const [rootTaskInventory, setRootTaskInventory] = useState(parentTaskInventory);
	const editableInEditMode = isInEditMode && editable;
	const timezone = useTimezone();

	useEffect(() => {
		setRootTaskInventory(parentTaskInventory);
	}, [parentTaskInventory]);

	const statusToLabelProps = pending => {
		const { quantity, original_quantity, rejected_quantity } = rootTaskInventory;
		const hasNotDeliveredChildren: boolean = hasChildrenNotDelivered();
		const isWayPointComplete: boolean = !!wayPointCheckinTime;
		const shouldShowOrange: boolean =
			pending && isWayPointComplete && !hasNotDeliveredChildren && !!rejected_quantity;
		return (
			<div className="label-container">
				<Label
					text={`${pending ? translations.collectText : translations.deliverText} ${
						quantity || 0
					} / ${original_quantity}`}
					size="small"
					className={classNames({
						orange: shouldShowOrange
					})}
				></Label>
				{isWayPointComplete && hasNotDeliveredChildren && <div className="circle"></div>}
			</div>
		);
	};

	const hasChildrenNotDelivered = (): boolean => {
		const taskInventoryChildren = getAllTaskInventoryChildren(rootTaskInventory, []);
		const isNotDelivered = taskInventoryChildren.filter(item => {
			return item.rejected_quantity > 0;
		});

		return !!isNotDelivered.length;
	};
	const TASK_INVENTORY_ITEMS = useMemo(
		() => [
			{
				content: getNumericFieldAsString(rootTaskInventory, TaskInventoryFields.ScanString),
				className: 'task-inventory-item task-inventory-scan-string',
				icon: faBarcodeScan,
				tooltipContent: translations.scanCode,
				path: TaskInventoryFields.ScanString
			},
			{
				content: getTaskInventoryDimensions(rootTaskInventory),
				className: 'task-inventory-item task-inventory-dimensions',
				tooltip: `${translations.width} x ${translations.height} x ${translations.inventoryLength}`,
				icon: faRuler,
				tooltipContent: translations.dimensions,
				path: TaskInventoryFields.Dimensions
			},
			{
				content: getNumericFieldAsString(rootTaskInventory, TaskInventoryFields.Weight),
				className: 'task-inventory-item task-inventory-weight',
				icon: faWeight,
				tooltipContent: translations.weight,
				path: TaskInventoryFields.Weight
			},
			{
				content: getNumericFieldAsString(rootTaskInventory, TaskInventoryFields.Price),
				className: 'task-inventory-item task-inventory-price',
				icon: faCoins,
				tooltipContent: translations.price,
				path: TaskInventoryFields.Price
			},
			{
				content: tryLimitLengthString(getNumericFieldAsString(rootTaskInventory, TaskInventoryFields.Note)),
				className: 'task-inventory-item task-inventory-notes',
				icon: faStickyNote,
				tooltipContent: `${translations.note}: ️${getNumericFieldAsString(
					rootTaskInventory,
					TaskInventoryFields.Note
				)}`,
				path: TaskInventoryFields.Note
			},
			{
				content: rootTaskInventory.scheduled_arrival ? (
					<DateDisplay
						date={rootTaskInventory.scheduled_arrival}
						format={SCHEDULED_ARRIVAL_FORMAT}
						timezone={timezone}
					/>
				) : (
					'N/A'
				),
				className: 'task-inventory-item task-inventory-scheduled-arrival',
				icon: faClock,
				tooltipContent: (
					<>
						{`${translations.itemArrival} `}
						<DateDisplay
							date={rootTaskInventory.scheduled_arrival}
							format={getDateFormat()}
							timezone={timezone}
						/>
					</>
				),
				path: TaskInventoryFields.ScheduledArrival
			}
		],
		[rootTaskInventory, translations]
	);

	const getFormItemName = useCallback(
		(propertyName: string): string => {
			return rootTaskInventory.id + TASK_INVENTORY_CONSTANTS.nameDelimiter + propertyName;
		},
		[rootTaskInventory]
	);

	const taskInventoryData = TASK_INVENTORY_ITEMS.map((item, index) => {
		if (_isNil(item.content) || !shownFields[item?.path]) return;

		return (
			<React.Fragment key={parentTaskInventory.id.toString() + index}>
				<div className={item.className}>
					<TooltipWrapper color={TOOLTIP_COLOR} title={item.tooltipContent}>
						{item.path === TaskInventoryFields.ScanString && rootTaskInventory?.scanned && (
							<FontAwesomeIcon icon={faScanner} className="scanner-icon" />
						)}
						<FontAwesomeIcon icon={item.icon} className="task-inventory-icon" />
						<span
							title={item.tooltip}
							className={classNames(
								{ 'task-inventory-tooltip': item.tooltip },
								'task-inventory-content-text'
							)}
						>
							{item.content}
						</span>
					</TooltipWrapper>
				</div>
				{index < TASK_INVENTORY_ITEMS.length - 1 && <VerticalLine />}
			</React.Fragment>
		);
	});
	const onTaskInventoryDelete = useCallback(() => {
		form.setValue(getFormItemName(TASK_INVENTORY_CONSTANTS.originalQuantity), 0, { shouldDirty: true });
	}, [form]);

	const getInventoryTitle = (): string => {
		const originalQuantity = rootTaskInventory.original_quantity;
		const id = _get(rootTaskInventory, 'name', rootTaskInventory.external_id);

		const taskInventorySum = getTaskInventorySum();
		const itemCount = rootTaskInventory.inventories?.length ? `(${taskInventorySum} ${translations.items})` : '';

		return `${originalQuantity} x ${id} ${itemCount}`;
	};

	const getTaskInventorySum = (): number => {
		const taskInventoryChildren = getAllTaskInventoryChildren(rootTaskInventory, []);
		return _sumBy(taskInventoryChildren.map(obj => obj.original_quantity));
	};

	return (
		<div className="task-inventory" style={{ marginLeft: `${depth * 3}%` }}>
			{depth > 0 && <div className="line" />}
			<div className="task-inventory-header">
				<div className="task-inventory-title">{getInventoryTitle()}</div>
			</div>
			<div className="task-inventory-content">
				<VerticalLine />
				<div className="task-inventory-quantity task-inventory-item">
					<span>{statusToLabelProps(rootTaskInventory.pending)}</span>
				</div>
				<VerticalLine />
				<div className="task-inventory-id task-inventory-item">
					<TooltipWrapper color={TOOLTIP_COLOR} title="#ID">
						#{_get(rootTaskInventory, 'external_id')}
					</TooltipWrapper>
					{editableInEditMode && (
						<FontAwesomeIcon
							icon={faTrash}
							className="task-inventory-delete"
							onClick={onTaskInventoryDelete}
						/>
					)}
				</div>
				<VerticalLine />
				{taskInventoryData}
			</div>
		</div>
	);
};
export default TaskInventory;
