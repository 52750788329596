import { Tooltip } from '../../components';
import React from 'react';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Props as TooltipProps } from '../../components/tooltip/tooltip';
import classNames from 'classnames';

interface Props {
	name: string;
	externalId: string;
	phone?: string;
	email?: string;
	openingHours?: string[];
	team?: string;
	customerIdText?: string;
	teamText?: string;
	className?: string;
	tooltipProps?: Partial<TooltipProps>;
	children: React.ReactNode;
}

const DEFAULT_CUSTOMER_ID_TEXT = 'Customer ID';
const DEFAULT_TEAM_TEXT = 'Team';

const CustomerTooltip: React.FC<Props> = ({
	name,
	externalId,
	phone,
	email,
	openingHours,
	customerIdText = DEFAULT_CUSTOMER_ID_TEXT,
	teamText = DEFAULT_TEAM_TEXT,
	team,
	className,
	tooltipProps,
	children
}) => {
	return (
		<Tooltip
			{...tooltipProps}
			overlayClassName={classNames('ant-tooltip-content', tooltipProps && tooltipProps.overlayClassName)}
			title={
				<div className={classNames('customer-tooltip', className)}>
					<span className="tooltip-header">{name}</span>
					<span className="tooltip-subheader">
						{customerIdText} #{externalId}
					</span>
					{phone && (
						<span className="tooltip-span">
							<FontAwesomeIcon icon={faPhone} className="icon" />
							{phone}
						</span>
					)}
					{email && (
						<span className="tooltip-span">
							<FontAwesomeIcon icon={faEnvelope} className="icon" />
							{email}
						</span>
					)}
					{openingHours && (
						<span className="tooltip-span opening-hours-container">
							<FontAwesomeIcon icon={faClock} className="icon" />
							<div className="opening-hours">
								{openingHours.map((value, index) => (
									<span key={index}>{value}</span>
								))}
							</div>
						</span>
					)}
					{team && (
						<span className="tooltip-span">
							<FontAwesomeIcon icon={faUsers} className="icon users-icon" />
							{teamText} {team}
						</span>
					)}
				</div>
			}
		>
			{children}
		</Tooltip>
	);
};

export default CustomerTooltip;
