import { ServiceArea, WayPoint } from '@bringg/types';
import { Map as MapboxMap, LngLatBounds } from 'mapbox-gl';
import { Feature } from 'geojson';
import { homeMarkerId } from '../../utils/markers-manager';
import { RouteLocation } from '../mapbox-route-tracker';

export const fitBoundsByRouteAndWayPoints = (map: MapboxMap, route: RouteLocation[], wayPoints: WayPoint[]) => {
	if (!map) {
		return;
	}
	const bounds = getCurrentBounds(map);
	route.forEach(item => {
		bounds.extend([item.lng, item.lat]);
	});

	wayPoints.forEach(item => {
		bounds.extend([item.lng, item.lat]);
	});

	if (!bounds.isEmpty()) {
		map.fitBounds(bounds, { padding: 70 });
	}
};

export const fitBoundsByServiceArea = (map: MapboxMap, serviceAreas: ServiceArea[]) => {
	if (!map) {
		return;
	}
	const bounds = getCurrentBounds(map);
	serviceAreas.forEach(serviceArea => {
		[serviceArea.polygon].flat().forEach((cord: any) => {
			bounds.extend(cord);
		});
	});

	if (!bounds.isEmpty()) {
		map.fitBounds(bounds);
	}
};

export const fitBoundsByMarkers = (map: MapboxMap, markersAsFeature: Feature[]) => {
	if (!map) {
		return;
	}
	const bounds = getCurrentBounds(map);
	markersAsFeature.forEach(feature => {
		if (feature.id !== homeMarkerId) {
			//@ts-ignore
			bounds.extend(feature.geometry.coordinates);
		}
	});

	if (!bounds.isEmpty()) {
		map.fitBounds(bounds, { padding: 70 });
	}
};

const getCurrentBounds = (map: MapboxMap) => {
	const currentBounds = map.getBounds();
	return new LngLatBounds(currentBounds.sw, currentBounds.ne);
};
