import * as React from 'react';
import _isNil from 'lodash/isNil';
import { getDataFormatByType, MetricType } from '../metric-counter/metric-counter';
import _map from 'lodash/map';
interface Props {
	metricValues: number[];
	metricTypes: MetricType[];
	className?: string;
}

const getMultipleDataFormatByType = (metricValues: number[], metricTypes: number[]) => {
	if (_isNil(metricValues)) {
		return null;
	}

	let result = `${getDataFormatByType(metricValues[0], metricTypes[0])}(`;
	result +=
		_map(metricValues.slice(1), (item, index) => {
			const type = metricTypes[index + 1];
			return getDataFormatByType(item, type);
		}).join(',') + ')';

	return result;
};

const MetricMultiple = ({ metricValues, metricTypes, className }: Props) => {
	return <div className={className}>{getMultipleDataFormatByType(metricValues, metricTypes)}</div>;
};

export default MetricMultiple;
