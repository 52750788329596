import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import times from 'lodash/times';
import noop from 'lodash/noop';
import classNames from 'classnames';

export interface StarRatingProps {
	rating: number;
	onRate?: (rate: number) => void;
	className?: string;
	style?: CSSProperties;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, onRate, className, style }) => {
	return (
		<div className={classNames('star-rating', className)} style={style}>
			{times(5, index => {
				const buttonRating = index + 1;
				const className = classNames('star-icon', { active: buttonRating <= rating });
				const onClick = () => {
					onRate(buttonRating);
				};
				return (
					<div key={index} className={className} onClick={onClick}>
						<FontAwesomeIcon icon={faStar} />
					</div>
				);
			})}
		</div>
	);
};

StarRating.defaultProps = {
	rating: 0,
	onRate: noop
};

export default StarRating;
