import { drawRoute, removeRoute } from '../mapbox-route-tracker/utils/mapbox-draw-route-util';
import { IdType, PolylineData } from '../../../types/maps.consts';
import { Map as MapboxMap } from 'mapbox-gl';

const polylinesMap: Map<IdType, PolylineData> = new Map();

export const updatePolylines = (map: MapboxMap, polylines?: PolylineData[]) => {
	removeOldPolylines(map);

	polylines?.forEach(polyline => {
		polylinesMap.set(polyline.id, polyline);
		drawRoute(
			map,
			{ id: polyline.id, locations: polyline.path },
			polyline.color,
			polyline.strokeOpacity,
			polyline.strokeWeight
		);
	});
};

const removeOldPolylines = (map: MapboxMap) => {
	polylinesMap.forEach(oldPolyline => {
		removeRoute(map, oldPolyline.id);
	});
	polylinesMap.clear();
};
