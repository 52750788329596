import React from 'react';
import MultiSelect, { MultiSelectOption } from '../multi-select/multi-select';
import { useCallback, useEffect, useState } from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _noop from 'lodash/noop';
import classNames from 'classnames';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export type MultiSelectTag = string;

interface MultiTagsSelectProps {
	tags: MultiSelectTag[];
	onTagsChange?: (tags: string[]) => void;
	className?: string;
	size?: SizeType;
	placeholder?: string;
	defaultValue?: string[];
	onSearch?: (value: any) => void;
	disabled?: boolean;
}

const mapTagsToOptions = (tags: MultiSelectTag[]): Partial<MultiSelectOption>[] => {
	return _map(tags, tag => ({
		value: tag
	}));
};

const MultiTagsSelect: React.FC<MultiTagsSelectProps> = ({
	tags,
	onTagsChange = _noop,
	className,
	size,
	placeholder,
	defaultValue,
	onSearch,
	disabled
}) => {
	const [filteredTags, setFilteredTags] = useState(tags);
	const [selectedTags, setSelectedTags] = useState(defaultValue);

	onSearch = onSearch ? onSearch : _noop;

	useEffect(() => {
		if (!selectedTags) {
			return;
		}

		const filteredTags = _filter(tags, (tag: MultiSelectTag) => {
			return !selectedTags.includes(tag);
		});

		setFilteredTags(filteredTags);
	}, [tags, selectedTags]);

	const onChange = useCallback(
		tags => {
			setSelectedTags(tags);
			onTagsChange(tags);
		},
		[onTagsChange, selectedTags]
	);

	return (
		<div className={classNames('multi-tags-select-container', className)}>
			<MultiSelect
				disabled={disabled}
				className="multi-tags-select"
				dropdownClassName="multi-tags-select-dropdown"
				menuItemSelectedIcon={<div className="hide-selected-item-icon"></div>}
				placeholder={placeholder}
				size={size}
				onChange={onChange}
				onSearch={onSearch}
				options={mapTagsToOptions(filteredTags)}
				value={selectedTags}
			/>
		</div>
	);
};

export default MultiTagsSelect;
