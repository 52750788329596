import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { AutoComplete, Modal, Table } from '../index';
import _times from 'lodash/times';
import _find from 'lodash/find';
import _maxBy from 'lodash/maxBy';
import _get from 'lodash/get';
import _reject from 'lodash/reject';
import _sortBy from 'lodash/sortBy';
import _isNil from 'lodash/isNil';
import classNames from 'classnames';
import {
	CSVUploaderProps,
	CSVUploaderState,
	CsvUploadResult,
	DEFAULT_COLUMNS_SELECTED_TEXT,
	DEFAULT_FINISH_TEXT,
	DEFAULT_SHOW_FIRST_ROW,
	DEFAULT_TOTAL_ROWS_TEXT,
	DEFAULT_UPLOAD_TEXT,
	DefaultAutoCompleteProps,
	DefaultModalProps,
	DefaultPaginationProps,
	IAutoCompleteOption
} from './csv-uploader.consts';
import { SelectValue } from 'antd/lib/select';
import { AutoCompleteProps } from '../auto-complete/auto-complete';
import DraggerView from './dragger-view/dragger-view';
import ResultsView from './results-view/results-view';
import { CSVtoArray } from './utils/csv-uploader-utils';

const HeaderAutoComplete = (
	options: IAutoCompleteOption[],
	onChange: (value: SelectValue) => void,
	autoCompleteProps: Partial<AutoCompleteProps>,
	defaultValue?: string
) => {
	return (
		<AutoComplete
			{...{ ...DefaultAutoCompleteProps, ...autoCompleteProps }}
			defaultValue={defaultValue}
			options={options}
			onChange={onChange}
		/>
	);
};

const getColumnTitle = (
	options: IAutoCompleteOption[],
	onChange: (value: SelectValue) => void,
	autoCompleteProps: Partial<AutoCompleteProps>,
	defaultValue?: string
) => {
	return _isNil(options) ? defaultValue : HeaderAutoComplete(options, onChange, autoCompleteProps, defaultValue);
};

const CSVUploader: FunctionComponent<CSVUploaderProps> = ({
	headers,
	headersOptions,
	onUpload,
	description,
	totalRowsText = DEFAULT_TOTAL_ROWS_TEXT,
	columnsSelectedText = DEFAULT_COLUMNS_SELECTED_TEXT,
	modalProps,
	autoCompleteProps,
	dragAndDropProps,
	tableProps,
	showFirstRow = DEFAULT_SHOW_FIRST_ROW,
	showDownloadTemplate,
	downloadTemplateButtonText,
	templateFileName,
	linesUploadedText,
	linesImportedText,
	linesWithErrorsText,
	uploadText = DEFAULT_UPLOAD_TEXT,
	finishText = DEFAULT_FINISH_TEXT
}: CSVUploaderProps) => {
	const [isModalVisible, setModalVisibility] = useState(true);
	const [componentState, setComponentState] = useState<CSVUploaderState>(CSVUploaderState.DraggerView);
	const [csvFile, setCsvFile] = useState(null);
	const [maxRowLength, setMaxRowLength] = useState(0);
	const [updatedHeaders, setUpdatedHeaders] = useState(headers ? [...headers] : []);
	const [tableData, setTableData] = useState([]);
	const [results, setResults] = useState<CsvUploadResult>(null);
	const [okText, setOkText] = useState(uploadText);

	const handleHeaderChange = useCallback(
		index => selectedHeader => {
			if (selectedHeader && _find(headersOptions, { value: selectedHeader })) {
				setUpdatedHeaders(updatedHeaders => [
					...updatedHeaders,
					{
						field_name: selectedHeader,
						field_index: index
					}
				]);
			} else {
				setUpdatedHeaders(updatedHeaders => _reject(updatedHeaders, { field_index: index }));
			}
		},
		[headersOptions]
	);

	const tableColumns = useMemo(() => {
		return _times(maxRowLength, index => {
			const columnSavedHeader = _find(updatedHeaders, { field_index: index + 1 });
			const columnSavedHeaderFieldName = _get(columnSavedHeader, 'field_name');

			return {
				title: getColumnTitle(
					headersOptions,
					handleHeaderChange(index + 1),
					autoCompleteProps,
					columnSavedHeaderFieldName
				),
				dataIndex: index.toString(),
				className: !columnSavedHeaderFieldName && 'unselected-column'
			};
		});
	}, [maxRowLength, updatedHeaders, autoCompleteProps, headersOptions]);

	const mapSerializedCsvToTableRows = useCallback((serializedCsv: string[][]) => {
		return serializedCsv.map((row, rowIndex) => Object.assign({ index: rowIndex }, row));
	}, []);

	const serializeCsvFile = useCallback(async (): Promise<string[][]> => {
		const text = await new Response(csvFile).text();
		const splitByRows = text.split('\n');

		if (!showFirstRow) {
			splitByRows.shift();
		}

		// Split each row to fields
		return splitByRows.map(row => CSVtoArray(row));
	}, [csvFile, showFirstRow]);

	useEffect(() => {
		if (!csvFile) {
			return;
		}

		const showTable = async () => {
			const serializedCsv = await serializeCsvFile();

			setMaxRowLength(
				Math.max(
					_maxBy(serializedCsv, row => row?.length).length,
					_maxBy(headers, header => header.field_index).field_index
				)
			);

			const tableRows = mapSerializedCsvToTableRows(serializedCsv);

			setTableData(tableRows);
		};

		showTable();
	}, [csvFile]);

	const onCancelHandler = useCallback(() => setModalVisibility(false), []);

	const onOkHandler = useCallback(async () => {
		if (componentState === CSVUploaderState.TableView) {
			setComponentState(CSVUploaderState.Uploading);
			const results = await onUpload(_sortBy(updatedHeaders, 'field_index'), csvFile);
			setResults(results);
			setOkText(finishText);
			setComponentState(CSVUploaderState.ResultsView);
		}

		if (componentState === CSVUploaderState.ResultsView) {
			setModalVisibility(false);
		}
	}, [updatedHeaders, csvFile, componentState, finishText, onUpload]);

	const handleFileUploaded = useCallback((file: Blob) => {
		setCsvFile(file);
		setComponentState(CSVUploaderState.TableView);
	}, []);

	const getTableFooter = useCallback(
		(): JSX.Element => (
			<span>
				{totalRowsText} {tableData.length}, {columnsSelectedText} {updatedHeaders.length}
			</span>
		),
		[totalRowsText, tableData, columnsSelectedText, updatedHeaders]
	);

	return (
		<Modal
			{...DefaultModalProps}
			{...modalProps}
			visible={isModalVisible}
			onCancel={onCancelHandler}
			onOk={onOkHandler}
			okText={okText}
			className={classNames('csv-uploader-modal', modalProps?.className)}
			okButtonProps={{
				loading: componentState === CSVUploaderState.Uploading,
				disabled: componentState === CSVUploaderState.DraggerView
			}}
			cancelButtonProps={{
				disabled: componentState === CSVUploaderState.ResultsView
			}}
		>
			{componentState === CSVUploaderState.DraggerView && (
				<DraggerView
					dragAndDropProps={dragAndDropProps}
					onFileUploaded={handleFileUploaded}
					headers={headers}
					showDownloadTemplate={showDownloadTemplate}
					downloadTemplateButtonText={downloadTemplateButtonText}
					templateFileName={templateFileName}
				/>
			)}
			{(componentState === CSVUploaderState.TableView || componentState === CSVUploaderState.Uploading) && (
				<>
					<span className="description">{description}</span>
					<Table
						{...tableProps}
						columns={tableColumns}
						collection={tableData}
						pagination={{ ...DefaultPaginationProps, ...tableProps?.pagination }}
						footer={getTableFooter}
						rowKey="index"
						className={classNames('csv-table', tableProps?.className)}
					/>
				</>
			)}
			{componentState === CSVUploaderState.ResultsView && (
				<ResultsView
					results={results}
					linesImportedText={linesImportedText}
					linesUploadedText={linesUploadedText}
					linesWithErrorsText={linesWithErrorsText}
				/>
			)}
		</Modal>
	);
};

export default CSVUploader;
