import { TaskInventory as TaskInventoryType, Task } from '@bringg/types';
interface AllTaskInventories {
	[key: number]: TaskInventoryType;
}
export const getParentTaskInventoryIds = (
	inventories: TaskInventoryType[],
	inventoriesIds: string[] = []
): string[] => {
	if (!inventories?.length) return;

	inventories.forEach(inventory => {
		if (!inventory?.inventories?.length) {
			return;
		} else {
			inventoriesIds.push(inventory.id.toString());
			getParentTaskInventoryIds(inventory?.inventories, inventoriesIds);
		}
	});
	return inventoriesIds;
};

export const getAllTaskInventoryChildren = (
	inventory: TaskInventoryType,
	inventories: TaskInventoryType[] = []
): TaskInventoryType[] => {
	if (!inventory?.inventories?.length) return inventories;

	inventory.inventories.forEach(child => {
		if (!inventory?.inventories?.length) {
			return;
		} else {
			inventories.push(child);
			getAllTaskInventoryChildren(child, inventories);
		}
	});
	return inventories;
};

export const getAllTaskInventory = (
	task: Task,
	wayPointDataId: number,
	allTaskInventories: AllTaskInventories = {}
): AllTaskInventories => {
	task?.task_inventories.forEach(taskInventory => {
		if (taskInventory.way_point_id === wayPointDataId) {
			allTaskInventories[taskInventory.id] = taskInventory;
		}
	});

	return allTaskInventories;
};
