import { MomentInput } from 'moment';
import React from 'react';
import moment from 'moment-timezone';
import DateDisplay, { getDateFormat, getTimeFormat } from './date-display';

const INVALID_DATE = '--:--';
interface Props {
	firstDate: MomentInput | string;
	secondDate: MomentInput | string;
	hourFormat: string;
	timezone?: string;
}
const FormattedTimeWindow = ({ firstDate, secondDate, hourFormat, timezone }: Props) => {
	const isSameDay = moment(firstDate).isSame(secondDate, 'day');
	const format = isSameDay ? getTimeFormat(hourFormat) : getDateFormat(hourFormat);

	return (
		<>
			<DateDisplay
				invalidDateText={INVALID_DATE}
				date={firstDate}
				format={getDateFormat(hourFormat)}
				timezone={timezone}
			/>
			<span> - </span>
			<DateDisplay invalidDateText={INVALID_DATE} date={secondDate} format={format} timezone={timezone} />
		</>
	);
};
export default FormattedTimeWindow;
