import React from 'react';
import { Inventory, TaskInventory as TaskInventoryType } from '@bringg/types';
import { Translations } from '../task-inventory';
import ButtonWrapper from '../../button/button';
import { useForm } from 'react-hook-form';
import TaskInventoryFormItems from './task-inventory-task-items';
import { TaskInventoriesFlagsAccess } from '../../../features/waypoint-card/wapoint-card';

interface Props {
	parentTaskInventory: TaskInventoryType;
	taskInventories: TaskInventoryType[];
	onQuantityChange: (massQuantityUpdate: TaskInventoryType[]) => void;
	onInventoriesChange: (inventories: Inventory[]) => void;
	onSave: () => void;
	onCancel: () => void;
	translations: Translations;
	taskInventory: TaskInventoryType;
	flagsAccess: TaskInventoriesFlagsAccess;
}

export const TASK_INVENTORY_CONSTANTS = {
	nameDelimiter: '-',
	width: 'width',
	height: 'height',
	length: 'length',
	originalQuantity: 'original_quantity'
};

const TaskInventoryForm: React.FC<Props> = props => {
	return (
		<form className="task-inventory-card-form">
			<TaskInventoryFormList {...props} />
		</form>
	);
};

const TaskInventoryFormList: React.FC<Props> = ({ taskInventory, translations, flagsAccess, ...props }) => {
	const form = useForm();
	const { handleSubmit, formState, reset } = form;
	const { dirtyFields, isDirty } = formState;
	let taskInventoryMap = new Map<number, TaskInventoryType>();

	const addToTaskInventoryMap = (taskInventoryID: number, taskInventory: TaskInventoryType) => {
		taskInventoryMap.set(taskInventoryID, taskInventory);
	};

	const onSave = handleSubmit => {
		let changedTaskInventories: TaskInventoryType[] = [];
		let changedTaskInventoriesMap = new Map<number | string, TaskInventoryType>();
		if (isDirty) {
			Object.keys(dirtyFields).forEach(dirtyField => {
				const splitDirtyField = dirtyField.split('-');
				const taskInventoryID = parseInt(splitDirtyField[0]);
				const taskInventoryKey = splitDirtyField[1];
				if (!changedTaskInventoriesMap.has(taskInventoryID)) {
					changedTaskInventoriesMap.set(taskInventoryID, taskInventoryMap.get(taskInventoryID));
				}
				changedTaskInventoriesMap.set(taskInventoryID, {
					...changedTaskInventoriesMap.get(taskInventoryID),
					[taskInventoryKey]: parseFloat(handleSubmit[dirtyField])
				});
			});
			changedTaskInventories = Array.from(changedTaskInventoriesMap.values());
			props.onQuantityChange(changedTaskInventories);
			onCancel(handleSubmit);
		}
	};

	const onCancel = handleSubmit => {
		reset(handleSubmit);
		props.onCancel();
	};

	const onDelete = (taskInventoryID: number) => {
		form.setValue(`${taskInventoryID}-${TASK_INVENTORY_CONSTANTS.originalQuantity}`, 0, { shouldDirty: true });
	};

	return (
		<div>
			<TaskInventoryFormItems
				taskInventory={taskInventory}
				depth={0}
				translations={translations}
				form={form}
				onDelete={onDelete}
				addToTaskInventoryMap={addToTaskInventoryMap}
				flagsAccess={flagsAccess}
			/>
			<div className="task-inventory-card-form-buttons">
				<ButtonWrapper className="task-inventory-card-form-button" onClick={handleSubmit(onCancel)}>
					{translations.cancel}
				</ButtonWrapper>
				<ButtonWrapper
					type={'primary'}
					className="task-inventory-card-form-button"
					disabled={!isDirty}
					onClick={handleSubmit(onSave)}
				>
					{translations.save}
				</ButtonWrapper>
			</div>
		</div>
	);
};

export default TaskInventoryForm;
