import * as React from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Tooltip } from 'antd';

export type TooltipTrigger = 'hover' | 'focus' | 'click' | 'contextMenu' | Array<string>;

export interface Props {
	title: string | React.ReactNode | (() => React.ReactNode);
	arrowPointAtCenter?: boolean;
	autoAdjustOverflow?: boolean;
	defaultVisible?: boolean;
	getPopupContainer?: () => HTMLElement;
	mouseEnterDelay?: number;
	mouseLeaveDelay?: number;
	overlayClassName?: string;
	overlayStyle?: object;
	placement?: TooltipPlacement;
	trigger?: TooltipTrigger;
	visible?: boolean;
	onVisibleChange?: (visible: boolean) => void;
	align?: object;
	children?: React.ReactNode[] | React.ReactNode;
	color?: string;
}

const TooltipWrapper: React.FunctionComponent<Props> = (props: Props) => {
	const { children, ...tooltipProps } = props;

	return <Tooltip {...tooltipProps}>{children}</Tooltip>;
};

export default TooltipWrapper;
