import React, { ReactNode } from 'react';
import { TaskNote } from '@bringg/types/types/task';
import DateDisplay from '../date-display/date-display';
import _isNil from 'lodash/isNil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import Modal from '../modal/modal';
import { useCallback, useState } from 'react';
import _noop from 'lodash/noop';
import TaskNotesForm from './task-notes-form/task-notes-form';
import MiniStaticMapLink, { MapData } from '../mini-static-map-link/mini-static-map-link';
import CardWrapper from '../card/card-wrapper';

export interface Translations {
	addedBy: string;
	addNote: string;
	addYourNote: string;
	save: string;
	cancel: string;
}

interface TaskNotesProps {
	taskNotes: TaskNote[];
	dateFormat: string;
	translations: Translations;
	onNoteAdded?: (note: string) => void;
	mapData?: MapData;
	editable?: boolean;
	timezone?: string;
}

const TaskNotes: React.FC<TaskNotesProps> = ({
	taskNotes,
	onNoteAdded = _noop,
	dateFormat,
	mapData,
	translations,
	editable = true,
	timezone
}: TaskNotesProps) => {
	const [showAddNoteForm, setShowAddNoteForm] = useState(false);

	const showForm = useCallback(() => {
		setShowAddNoteForm(true);
	}, [showAddNoteForm]);

	const hideForm = useCallback(() => {
		setShowAddNoteForm(false);
	}, [showAddNoteForm]);

	const onSaveForm = useCallback(
		(note: string) => {
			onNoteAdded(note);
			hideForm();
		},
		[onNoteAdded]
	);

	const taskTitle = (taskNote: TaskNote): ReactNode => {
		return (
			<div className="task-note-header">
				<span className="task-note-info">{taskNote.author_name}</span>
				<DateDisplay
					date={taskNote.created_at}
					format={dateFormat}
					className="task-note-header-date"
					timezone={timezone}
				/>
			</div>
		);
	};
	const taskNotesList = taskNotes.map((taskNote: TaskNote) => (
		<CardWrapper title={taskTitle(taskNote)} key={taskNote.id.toString()} className="task-note">
			<div className="task-note-body">
				{_isNil(taskNote.url) ? (
					<div className="task-note-content">{taskNote.note}</div>
				) : (
					<a className="task-note-link" href={taskNote.url.toString()} target="_blank">
						{taskNote.note}
					</a>
				)}

				{mapData && taskNote.lat && taskNote.lng && (
					<MiniStaticMapLink mapData={mapData} lat={taskNote.lat} lng={taskNote.lng} />
				)}
			</div>
		</CardWrapper>
	));

	return (
		<div className="task-notes-container">
			<div className="task-notes-list">{taskNotesList}</div>

			{editable && (
				<>
					<div className="task-notes-add-note" onClick={showForm}>
						<FontAwesomeIcon icon={faPlus} className="task-notes-add-note-icon" />
						<span className="task-notes-add-note-link">{translations.addNote}</span>
					</div>

					<Modal
						destroyOnClose={true}
						title={translations.addNote}
						className="task-notes-form"
						visible={showAddNoteForm}
						onOk={hideForm}
						onCancel={hideForm}
						footer={null}
					>
						<TaskNotesForm onSave={onSaveForm} onCancel={hideForm} translations={translations} />
					</Modal>
				</>
			)}
		</div>
	);
};

export default TaskNotes;
