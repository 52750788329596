import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import classNames from 'classnames';

interface Translations {
	clickableText: string;
	beforeClickableText: string;
	afterClickableText: string;
}

interface Props {
	translations: Translations;
	onClickSwitch: () => void;
	onToggle: (isOpen: boolean) => void;
	isOpen: boolean;
	blockedSwitching?: boolean;
}

const SoftRolloutBar: React.FC<Props> = ({ translations, onClickSwitch, onToggle, isOpen, blockedSwitching }) => {
	const open = () => onToggle(true);
	const close = () => onToggle(false);

	return (
		<div className="soft-rollout-bar-container">
			<div className={classNames('soft-rollout-bar', { 'hide-rollout-bar': !isOpen })}>
				<div className="soft-rollout-bar-content">
					<span className="soft-rollout-bar-close-icon" onClick={close}>
						<FontAwesomeIcon icon={faTimesCircle} className="icon" />
					</span>
					&nbsp;
					<span>{translations.beforeClickableText}</span>
					{!blockedSwitching && (
						<>
							&nbsp;
							<span className="clickable-content" onClick={onClickSwitch}>
								{translations.clickableText}
							</span>
							&nbsp;
							<span>{translations.afterClickableText}</span>
						</>
					)}
				</div>
			</div>

			<div className={classNames('rollout-bar-closed', { 'hide-rollout-bar': isOpen })} onClick={open}>
				<div className="rollout-bar-closed-icon">
					<FontAwesomeIcon icon={faInfoCircle} />
				</div>
			</div>
		</div>
	);
};

export default SoftRolloutBar;
