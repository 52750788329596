import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/pro-regular-svg-icons/faChevronCircleUp';
import { Translations } from '../task-inventory/task-inventory';
import _noop from 'lodash/noop';
import classNames from 'classnames';
interface expandCollapseButtonProps {
	onClick?: () => void;
	isExpanded?: boolean;
	translations: Translations;
}

const ExpandCollapseButton: React.FC<expandCollapseButtonProps> = ({ onClick = _noop, isExpanded, translations }) => {
	const expandCollapseTitle = isExpanded ? translations.collapseAll : translations.expandAll;
	return (
		<div className={classNames('expand-collapse-button', { expanded: isExpanded })} onClick={onClick}>
			<FontAwesomeIcon icon={faChevronCircleUp} />
			<span>{expandCollapseTitle}</span>
		</div>
	);
};

export default ExpandCollapseButton;
