import * as React from 'react';
import Form, { FormItemProps } from 'antd/lib/form';

export interface Props extends FormItemProps {}

const FormItemWrapper = (props: Props) => {
	const { children, ...formItemProps } = props;
	return <Form.Item {...formItemProps}>{children}</Form.Item>;
};

export default FormItemWrapper;
