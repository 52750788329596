import React, { memo } from 'react';
import { default as InputFilter, InputProps } from './components/input';
import { default as CheckboxFilter, CheckboxProps } from './components/checkbox';
import { default as FiltersDivider } from './components/filters-divider';
import { default as SelectFilter, SelectProps } from './components/select/select';
import FilterMultiselect, { MultiselectProps } from './components/multiselect/multiselect';

import './filter-bar.scss';

const FilterBar = ({ children }) => {
	return <div className="filters-panel">{children}</div>;
};

const Input = memo(({ value, placeholder, onChange }: InputProps) => (
	<div className="filter-item filters-search">
		<InputFilter value={value} placeholder={placeholder} onChange={onChange} />
	</div>
));
FilterBar.Input = Input;

const Checkbox = memo(({ label, checked, onChange }: CheckboxProps) => (
	<CheckboxFilter label={label} checked={checked} onChange={onChange} />
));
FilterBar.Checkbox = Checkbox;

const Multiselect = memo(({ onChange, value, options, translations }: MultiselectProps) => (
	<div className="filter-item">
		<FilterMultiselect value={value} options={options} translations={translations} onChange={onChange} />
	</div>
));
FilterBar.Multiselect = Multiselect;

const Dropdown = memo(({ onChange, value, options, placeholder }: SelectProps) => (
	<SelectFilter value={value} options={options} placeholder={placeholder} onChange={onChange} />
));
FilterBar.Dropdown = Dropdown;

const Divider = memo(() => <FiltersDivider />);
FilterBar.Divider = Divider;

const InputsGroup = memo(({ children }) => <div className="filter-checkbox-group">{children}</div>);
FilterBar.InputsGroup = InputsGroup;

export default FilterBar;
