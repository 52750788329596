import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons/faLocationArrow';
import Modal from '../modal/modal';
import { MapboxProps } from '../mapbox/mapbox';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';

export interface MapData extends Pick<MapboxProps, 'apiKey' | 'zoom'> {
	staticMapUrl: string;
}

export interface Props {
	lat: number;
	lng: number;
	width?: number;
	height?: number;
	mapData: MapData;
}

const WIDTH = 470;
const HEIGHT = 550;

const MiniStaticMapLink: React.FC<Props> = ({ lat, lng, width = WIDTH, height = HEIGHT, mapData }) => {
	const [showMiniMap, setShowMiniMap] = useState(false);

	const openMiniMap = useCallback(() => {
		setShowMiniMap(true);
	}, []);

	const hideMiniMap = useCallback(() => {
		setShowMiniMap(false);
	}, []);

	const { apiKey, staticMapUrl, zoom } = mapData;
	const mapZoom = zoom ?? 16;
	const imgUrl = `${staticMapUrl}/${lng},${lat},${mapZoom},0.00,0.00/${width}x${height}@2x?access_token=${apiKey}`;
	return (
		<>
			<span className="mini-static-map-link-button" onClick={openMiniMap}>
				<FontAwesomeIcon icon={faLocationArrow} className="mini-static-map-link-icon" />
			</span>

			<Modal
				title={null}
				className="task-inventory-edit-modal"
				visible={showMiniMap}
				onOk={hideMiniMap}
				onCancel={hideMiniMap}
				footer={null}
			>
				<div className="mini-map-container" style={{ width, height }}>
					<img className="mini-map-image" src={imgUrl} alt={`${lng},${lat}`} width={width} height={height} />

					<FontAwesomeIcon icon={faMapMarker} size={'3x'} className="mini-map-marker-icon" />
				</div>
			</Modal>
		</>
	);
};

export default MiniStaticMapLink;
