import React, { CSSProperties, useEffect, useState } from 'react';
import classname from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { get as _get } from 'lodash';
import useCombinedRefs from '../../custom-hooks/combine-refs/combine-refs';

export interface InputProps {
	value?: string | number;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder: string | React.ReactNode;
	type?: 'text' | 'password' | 'number';
	name?: string;
	className?: string;
	isDisabled?: boolean;
	ref?: React.Ref<HTMLInputElement>;
	style?: CSSProperties;
	autoComplete?: string;
	error?: string;
	defaultValue?: string | number | string[];
	readOnly?: boolean;
	icon?: React.ReactNode;
	inputClassName?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			value,
			onChange,
			placeholder,
			type,
			autoComplete,
			name,
			className,
			isDisabled = false,
			style,
			error,
			defaultValue,
			icon,
			readOnly,
			inputClassName
		},
		ref
	) => {
		const innerRef = React.useRef(null);
		const combinedRef = useCombinedRefs(ref, innerRef);
		const [isFocused, setIsFocused] = useState(false);
		const [shouldFloat, setFloat] = useState(false);

		useEffect(() => {
			const shouldFloat = isFocused || value || _get(innerRef, 'current.value');
			setFloat(shouldFloat);
		}, [isFocused, value, innerRef.current]);

		const onFocus = () => {
			setIsFocused(true);
		};
		const onBlur = () => {
			setIsFocused(false);
		};

		return (
			<div
				className={`floating-input-container ${classname({
					float: shouldFloat,
					disabled: isDisabled,
					[className]: className,
					error: Boolean(error)
				})}`}
				style={style}
				onFocus={onFocus}
				onBlur={onBlur}
			>
				<span className="placeholder">{placeholder}</span>
				<input
					autoComplete={autoComplete}
					ref={combinedRef}
					className={`floating-input ${classname(inputClassName)}`}
					value={value}
					onChange={onChange}
					name={name}
					type={type}
					disabled={isDisabled}
					defaultValue={defaultValue}
					readOnly={readOnly}
				/>
				{icon && <span className="input-icon">{icon}</span>}
				{error && (
					<span className={`input-error ${classname(`${name}-error`)}`}>
						<FontAwesomeIcon icon={faExclamationTriangle} />
						<span className="error-message"> {error}</span>
					</span>
				)}
			</div>
		);
	}
);

export default Input;
