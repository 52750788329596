import * as React from 'react';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import AvatarWrapper from '../../../components/avatar/avatar';
import RateWrapper from '../../../components/rate/rate';
import BatteryIcon from '../../../components/battery-icon/battery-icon';

interface Props {
	driver: Partial<Bringg.User>;
	team: Partial<Bringg.Team>;
	nameLink: JSX.Element | JSX.Element[];
	iconLink?: JSX.Element | JSX.Element[];
	showExtraDetails?: boolean;
	shiftStatus?: JSX.Element | JSX.Element[];
	sendMessageText?: any;
}

export const DriverCard: React.FunctionComponent<Props> = (props: Props) => {
	const { profile_image, job_description, average_rating, phone, email, battery, status } = props.driver;
	const { shiftStatus, sendMessageText } = props;
	const teamName = props.team.name;
	const showExtraDetails = props.showExtraDetails;

	return (
		<Row>
			<Col span={24}>
				<Row className="driver-details-wrapper">
					<div className="driver-image">
						<AvatarWrapper size="large" src={profile_image} />
					</div>
					<div className="driver-name">
						<>{props.nameLink}</>
					</div>
					<div className="driver-link">
						<>{props.iconLink}</>
					</div>
					<div className="driver-info">
						<span>{job_description}</span>
						<span>{teamName}</span>
					</div>
					<div className="driver-rating">
						<RateWrapper disabled={true} allowHalf={true} value={average_rating} />
					</div>
					{showExtraDetails && (
						<div className="extra-details">
							<div className="driver-phone">{phone}</div>
							<div className="driver-email">{email}</div>
							<hr />
							<div className="labels">
								<span className="left-labels">
									<span
										className={`driver-label ${
											status === 'online' ? 'online-label' : 'offline-label'
										}`}
									>
										{status.toUpperCase()}
									</span>
									<>{shiftStatus}</>
								</span>
								{battery && (
									<span className="battery-label">
										{battery}%
										<BatteryIcon batteryPercentage={battery} className="battery-icon" />
									</span>
								)}
							</div>
							{
								// future functionality - send message to the driver
								// just don't make 'sendMessageText' required, think there are cases when task kinda
								// not active and there is no point to send messages
								// so presence of 'sendMessageText' can be used as implicit indicator or add
								// explicit 'chatEnabled? = true'
								false && (
									<div>
										<textarea placeholder={sendMessageText} className="message-text" />
										<FontAwesomeIcon icon={faArrowRight} className="send-message" />
									</div>
								)
							}
						</div>
					)}
				</Row>
			</Col>
		</Row>
	);
};

export default DriverCard;
