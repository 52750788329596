import { Team, User } from '@bringg/types';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import SelectWrapper from '../select/select';
import _isNull from 'lodash/isNull';

export interface Translations {
	selectItem: string;
	unassigned: string;
}

type ItemTypes = User | Team;

export interface AssignUserProps {
	items: ItemTypes[];
	selectedItemId: number;
	onSelectedItem: (itemId?: number) => void;
	translations: Translations;
	enableUnassign?: boolean;
	renderOption?: (item: ItemTypes) => React.ReactNode;
	open?: boolean;
	disabled?: boolean;
}

const AssignComponent: React.FC<AssignUserProps> = ({
	items,
	selectedItemId,
	onSelectedItem,
	enableUnassign = true,
	translations,
	renderOption,
	open,
	disabled
}) => {
	const selectWrapperRef = useRef<SelectWrapper>();
	const unassignedItem = { id: -1, name: translations.unassigned };
	const [selectOptions, setSelectOptions] = useState([]);
	const [value, setValue] = useState(selectedItemId);

	useEffect(() => {
		selectWrapperRef.current.getSelectRef()[open ? 'focus' : 'blur']();
	}, [open]);

	useEffect(() => {
		if (_isNull(selectedItemId)) {
			setValue(undefined);
		} else {
			setValue(selectedItemId);
		}
	}, [selectedItemId]);

	useEffect(() => {
		if (enableUnassign) {
			setSelectOptions([unassignedItem, ...items]);
		} else {
			setSelectOptions([...items]);
		}
	}, [items, enableUnassign]);

	const handleItemSelected = useCallback(
		itemId => {
			if (itemId === unassignedItem.id) {
				onSelectedItem && onSelectedItem();
			} else {
				onSelectedItem && onSelectedItem(itemId);
			}
		},
		[onSelectedItem, unassignedItem]
	);

	const transformedOptions = useMemo(() => {
		return renderOption
			? selectOptions.map(item => {
					return { ...item, render: renderOption(item) };
			  })
			: selectOptions;
	}, [renderOption, selectOptions]);

	return (
		<div className="assign-component-container">
			<SelectWrapper
				showSearch
				disabled={disabled}
				className="assign-component-select"
				placeholder={translations.selectItem}
				onSelect={handleItemSelected}
				options={transformedOptions}
				value={value}
				dropdownClassName="assign-component-dropdown"
				filterOption={(input, option) =>
					option.props.title.toString().toLowerCase().includes(input.toLowerCase())
				}
				open={open}
				ref={selectWrapperRef}
			/>
		</div>
	);
};

export default AssignComponent;
