import React from 'react';
import { Select, SelectProps } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { RefSelectProps } from 'antd/lib/select';

export type OptionWrapper = {
	id: string | number;
	name: string;
	render?: React.ReactNode;
	dropDownKey?: string;
	disabled?: boolean;
};

interface Props extends Omit<SelectProps<any>, 'options'> {
	onSelect?: (value: any) => void; // this is for not casting value in all the places
	onDeselect?: (value: any) => void; // this is for not casting value in all the places
	onChange?: (value: any) => void; // this is for not casting value in all the places;
	children?: React.ReactNode;
	options?: OptionWrapper[];
}

const Option = Select.Option;

const getWrapperOptions = (options: OptionWrapper[]) => {
	return options.map(({ id, name, render, dropDownKey, disabled }) => (
		<Option key={id} value={id} title={name} data-testid={`${dropDownKey}-dropdown-option`} disabled={disabled}>
			{render || name}
		</Option>
	));
};

class SelectWrapper extends React.PureComponent<Props> {
	static defaultProps = {
		mode: 'default'
	};

	private selectRef = React.createRef<RefSelectProps>();

	render() {
		const { children, options, ...selectProps } = this.props;

		return (
			<div className="select-wrapper">
				{!_isEmpty(options) ? (
					<Select ref={this.selectRef} {...selectProps}>
						{getWrapperOptions(options)}
					</Select>
				) : (
					<Select ref={this.selectRef} {...selectProps}>
						{children}
					</Select>
				)}
			</div>
		);
	}

	public getSelectRef() {
		return this.selectRef.current;
	}
}

export default SelectWrapper;

export { Option };
