import React from 'react';
import Timezone from '../timezone/timezone';
import { getFormattedTimeWindow } from '../date-display/date-display';

export type PromisedTimeWindow = {
	promisedStartTime: string;
	promisedEndTime: string;
};

interface Props {
	promisedTimeWindow: PromisedTimeWindow;
	hourFormat: string;
	timezone?: string;
	useTeamTimezone?: boolean;
}

const TaskPromisedTimeWindow: React.FC<Props> = ({ promisedTimeWindow, timezone, hourFormat, useTeamTimezone }) => {
	const { promisedStartTime, promisedEndTime } = promisedTimeWindow;

	const displayWindow = getFormattedTimeWindow(
		promisedStartTime,
		promisedEndTime,
		hourFormat,
		useTeamTimezone && timezone
	);
	return (
		<div className="task-promised-time-window">
			<span className="time-window">{displayWindow}</span>
			<Timezone timezone={timezone} />
		</div>
	);
};

export default TaskPromisedTimeWindow;
