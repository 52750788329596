import React from 'react';
import { Input } from 'antd';
import { TaskInventory as TaskInventoryType, BaseTaskInventory } from '@bringg/types';
import { Translations as TaskInventoryTranslations } from '../task-inventory/task-inventory';
const { Search } = Input;

export interface Translations extends TaskInventoryTranslations {}

interface TaskInventoriesSearchProps {
	taskInventories?: TaskInventoryType[];
	allTaskInventories?: AllTaskInventories;
	getAllTaskInventoryChildren?: (
		inventory: TaskInventoryType,
		inventories: TaskInventoryType[]
	) => TaskInventoryType[];
	setFilteredInventories?: (taskInventories: TaskInventoryType[]) => void;
	changeActiveKey?: (key: string[]) => void;
	translations: Translations;
}

interface AllTaskInventories {
	[key: number]: TaskInventoryType;
}

const TaskInventoriesSearch: React.FC<TaskInventoriesSearchProps> = ({
	taskInventories,
	allTaskInventories,
	getAllTaskInventoryChildren,
	setFilteredInventories,
	changeActiveKey,
	translations
}) => {
	const getAllParentsIds = (child: BaseTaskInventory, parents: number[]) => {
		if (!child.parent_task_inventory_id) return;
		parents.push(child.parent_task_inventory_id);
		const parent = allTaskInventories[child.parent_task_inventory_id];
		getAllParentsIds(parent, parents);
	};

	const doesItemIncludeSearchValue = (item: TaskInventoryType, searchVal: string): boolean => {
		searchVal = searchVal.toLocaleLowerCase();
		return (
			item?.external_id?.toLocaleLowerCase().includes(searchVal) ||
			item?.scan_string?.toLocaleLowerCase().includes(searchVal) ||
			item?.name?.toLocaleLowerCase().includes(searchVal)
		);
	};

	const handleSearch = e => {
		const searchValue = e.target.value;
		if (!searchValue) {
			setFilteredInventories(taskInventories);
			changeActiveKey([]);
			return;
		}

		const searchResults = [];
		const rootParentsSearchResults = taskInventories.filter(item => {
			const itemChildren = getAllTaskInventoryChildren(item, []);
			const filteredChildren = itemChildren.filter(child => {
				return doesItemIncludeSearchValue(child, searchValue) && searchResults.push(child);
			});

			return doesItemIncludeSearchValue(item, searchValue) || filteredChildren.length;
		});

		const allParentsIdsToExpand = [];

		searchResults.forEach(taskInventory => {
			getAllParentsIds(taskInventory, allParentsIdsToExpand);
		});

		setFilteredInventories(rootParentsSearchResults);
		changeActiveKey(allParentsIdsToExpand);
	};

	return (
		<div className="search-container">
			<Search className="search-component" onChange={handleSearch} placeholder={translations.searchPlaceholder} />
		</div>
	);
};

export default TaskInventoriesSearch;
