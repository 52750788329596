import * as React from 'react';
import { Moment, MomentInput } from 'moment';
import { TimePicker } from 'antd';

interface Props {
	value?: Moment;
	defaultValue?: Moment;
	handleTimeChange?: (date: MomentInput) => void;
	timePickerFormat?: string;
	use12Hours?: boolean;
	className?: string;
	placeholder?: string;
	allowClear?: boolean;
}

const TimePickerWrapper: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<TimePicker
			use12Hours={props.use12Hours}
			format={props.timePickerFormat}
			value={props.value}
			defaultValue={props.defaultValue}
			onChange={props.handleTimeChange}
			className={props.className}
			placeholder={props.placeholder}
			allowClear={props.allowClear}
		/>
	);
};

TimePickerWrapper.defaultProps = {
	timePickerFormat: 'h:mm a',
	use12Hours: true,
	className: 'time-picker-input'
};

export default TimePickerWrapper;
