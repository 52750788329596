import React, { ChangeEvent, memo } from 'react';
import { Input as AntdInput } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

export interface InputProps {
	value: string;
	placeholder: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({ value, placeholder, onChange }: InputProps) => {
	return (
		<div className="filter-item filters-search">
			<AntdInput
				value={value}
				suffix={<FontAwesomeIcon icon={faSearch} />}
				placeholder={placeholder}
				onChange={onChange}
			/>
		</div>
	);
};

export default memo(Input);
