import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Input from '../../../components/floating-placeholder-input/input';
import { AutoComplete, DatePicker, Popconfirm } from 'antd';
import moment, { Moment } from 'moment';
import TimePickerWrapper from '../../../components/time-picker/time-picker';
import { AutoCompleteAddress, BringgGeocodedAddress, WayPoint } from '@bringg/types';
import classNames from 'classnames';
import ButtonWrapper from '../../../components/button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { Controller, useForm } from 'react-hook-form';
import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _toNumber from 'lodash/toNumber';
import { Switch } from '../../../components/switch/switch';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { useDebounce } from '../../../custom-hooks/use-debounce/use-debounce';
import { getDateFormat } from '../../../components/date-display/date-display';

const { Option } = AutoComplete;

export interface Translations {
	destination: string;
	addressLineOne: string;
	addressLineTwo: string;
	borough: string;
	city: string;
	zipcode: string;
	latitude: string;
	longitude: string;
	scheduleTo: string;
	date: string;
	time: string;
	startDate: string;
	startTime: string;
	timeWindow: string;
	endDate: string;
	endTime: string;
	etos: string;
	contact: string;
	name: string;
	phone: string;
	email: string;
	confirmDelete: string;
	yes: string;
	no: string;
	asap: string;
}

interface WayPointCardFormProps {
	wayPoint: WayPoint;
	onSave: (wayPoint: WayPoint) => void;
	onCancel: () => void;
	onDelete: (wayPointId: number) => void;
	translations: Translations;
	fetchAddresses: (query) => Promise<AutoCompleteAddress[]>;
	getPlaceDetails: (placeId) => Promise<BringgGeocodedAddress>;
}

const getFormSection = (
	title: string,
	content: ReactElement,
	inlineSection = true,
	className?: string
): ReactElement => (
	<div
		className={classNames(
			'waypoint-card-form-section',
			{ 'waypoint-card-form-inline-section': inlineSection },
			className
		)}
		section-title={title}
	>
		{content}
	</div>
);

const convertDateToMoment = (dateString: string): Moment => {
	if (!dateString) {
		return null;
	}

	return moment(dateString);
};

const convertSecondsToMoment = (seconds: number): Moment => {
	return moment.utc((seconds * 1000) / 60);
};

const convertMomentToSeconds = (moment: Moment): number => {
	return (moment.toDate().getTime() / 1000) * 60;
};

const WayPointCardForm: React.FC<WayPointCardFormProps> = ({
	wayPoint,
	onSave,
	onCancel,
	onDelete,
	translations,
	fetchAddresses,
	getPlaceDetails
}) => {
	const delay = 300;
	const dateFormat = getDateFormat();
	const [wayPointData, setWayPointData] = useState({ ...wayPoint });
	const { control, handleSubmit, setValue, reset, getValues } = useForm({ mode: 'onChange' });
	const [addressOptions, setAddressOptions] = useState([]);
	const [isOutOfRange, setIsOutOfRange] = useState(false);
	const [query, setQuery] = useState('');
	const debouncedQuery = useDebounce(query, delay);

	const onSubmit = useCallback((formFields, event) => {
		const NUMBERS_FIELDS = ['lat', 'lng'];

		_forEach(NUMBERS_FIELDS, fieldName => {
			formFields[fieldName] = _toNumber(formFields[fieldName]);
		});

		if (formFields.etos) {
			formFields.etos = convertMomentToSeconds(formFields.etos);
		}

		const editedWayPointData = _mapValues(formFields, field =>
			moment.isMoment(field) ? (field as Moment).toISOString() : field
		);

		setWayPointData(Object.assign(wayPointData, editedWayPointData));
		onSave(wayPointData);
	}, []);

	const onCancelForm = () => {
		onCancel();
		reset();
	};

	const onAddressSelected = async address => {
		const placeId = addressOptions.find(item => item.address === address).place_id;
		const addressDetails = await getPlaceDetails(placeId);
		setValue('city', addressDetails.city);
		setValue('zipcode', addressDetails.zipcode);
		setValue('lat', addressDetails.lat);
		setValue('lng', addressDetails.lng);
	};

	useEffect(() => {
		validateScheduledAtDate();
		const searchAddresses = async () => {
			if (!debouncedQuery) {
				setAddressOptions([]);
				return;
			}
			const options = await fetchAddresses(debouncedQuery);
			setAddressOptions(options);
		};
		searchAddresses();
	}, [debouncedQuery]);

	const handleKeyDown = event => {
		if (event.keyCode === 27) {
			onCancelForm();
		}
	};

	const isInTimeWindowRange = date => {
		let noEarlierThenDate = getValues('no_earlier_than');
		let noLaterThenDate = getValues('no_later_than');
		noEarlierThenDate = moment(noEarlierThenDate, dateFormat);
		noLaterThenDate = moment(noLaterThenDate, dateFormat);
		return !date.isBetween(noEarlierThenDate, noLaterThenDate, 'd', '[]');
	};

	const validateScheduledAtDate = () => {
		let scheduledAtDate = getValues('scheduled_at');
		scheduledAtDate = moment(scheduledAtDate, dateFormat);
		isInTimeWindowRange(scheduledAtDate) ? setIsOutOfRange(true) : setIsOutOfRange(false);
	};

	return (
		<form className="waypoint-card-form" onKeyDown={handleKeyDown}>
			{getFormSection(
				translations.destination,
				<>
					<div
						className="waypoint-card-form-address-line-one"
						input-placeholder={translations.addressLineOne}
					>
						<Controller
							as={
								<AutoComplete
									className="waypoint-card-autocomplete"
									onSelect={onAddressSelected}
									onSearch={query => setQuery(query)}
								>
									{addressOptions &&
										addressOptions.map(({ address }) => {
											return (
												<Option key={address} value={address}>
													{address}
												</Option>
											);
										})}
								</AutoComplete>
							}
							defaultValue={wayPointData.address}
							name={'address'}
							control={control}
						/>
					</div>

					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.addressLineTwo} />}
						defaultValue={wayPointData.address_second_line || ''}
						name={'address_second_line'}
						control={control}
					/>

					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.borough} />}
						defaultValue={wayPointData.borough || ''}
						name={'borough'}
						control={control}
					/>

					<Controller
						as={
							<Input
								className="waypoint-card-form-input"
								placeholder={translations.city}
								value={wayPointData.city}
							/>
						}
						defaultValue={wayPointData.city || ''}
						name={'city'}
						control={control}
					/>

					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.zipcode} />}
						defaultValue={wayPointData.zipcode || ''}
						name={'zipcode'}
						control={control}
					/>

					<div className="waypoint-card-form-location-inputs-container">
						<Controller
							as={
								<Input
									className="waypoint-card-form-input"
									type="number"
									placeholder={translations.latitude}
								/>
							}
							defaultValue={wayPointData.lat || ''}
							name={'lat'}
							control={control}
						/>

						<Controller
							as={
								<Input
									className="waypoint-card-form-input"
									type="number"
									placeholder={translations.longitude}
								/>
							}
							defaultValue={wayPointData.lng || ''}
							name={'lng'}
							control={control}
						/>
					</div>
				</>
			)}

			{getFormSection(
				translations.scheduleTo,
				<>
					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.date}>
						<Controller
							render={({ value, onChange }) => (
								<DatePicker
									className={`waypoint-card-form-picker ${isOutOfRange ? 'error' : ''}`}
									allowClear={false}
									disabledDate={isInTimeWindowRange}
									value={value}
									onChange={value => {
										onChange(value);
										validateScheduledAtDate();
									}}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.scheduled_at) || ''}
							name={'scheduled_at'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.time}>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.scheduled_at) || ''}
							name={'scheduled_at'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-asap-container">
						<Controller
							render={({ value, onChange }) => (
								<Switch
									checked={value}
									onChange={onChange}
									unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
									checkedChildren={<FontAwesomeIcon icon={faCheck} />}
								/>
							)}
							name={'asap'}
							defaultValue={wayPointData.asap}
							control={control}
						/>
						<span>{translations.asap}</span>
					</div>
				</>
			)}

			{getFormSection(
				translations.timeWindow,
				<>
					<div
						className="waypoint-card-form-date-picker-container"
						input-placeholder={translations.startDate}
					>
						<Controller
							render={({ value, onChange }) => (
								<DatePicker
									className="waypoint-card-form-picker"
									allowClear={false}
									value={value}
									onChange={value => {
										onChange(value);
										validateScheduledAtDate();
									}}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.no_earlier_than) || ''}
							name={'no_earlier_than'}
							control={control}
						/>
					</div>

					<div
						className="waypoint-card-form-date-picker-container"
						input-placeholder={translations.startTime}
					>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.no_earlier_than) || ''}
							name={'no_earlier_than'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.etos}>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									use12Hours={false}
									timePickerFormat={'HH:mm:ss'}
									defaultValue={moment('00:00:00', 'HH:mm:ss')}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertSecondsToMoment(wayPointData.etos) || ''}
							name={'etos'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.endDate}>
						<Controller
							render={({ value, onChange }) => (
								<DatePicker
									className="waypoint-card-form-picker"
									allowClear={false}
									value={value}
									onChange={value => {
										onChange(value);
										validateScheduledAtDate();
									}}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.no_later_than) || ''}
							name={'no_later_than'}
							control={control}
						/>
					</div>

					<div className="waypoint-card-form-date-picker-container" input-placeholder={translations.endTime}>
						<Controller
							render={({ value, onChange }) => (
								<TimePickerWrapper
									value={value}
									handleTimeChange={onChange}
									className="waypoint-card-form-picker"
									allowClear={false}
								/>
							)}
							defaultValue={convertDateToMoment(wayPointData.no_later_than) || ''}
							name={'no_later_than'}
							control={control}
						/>
					</div>
				</>
			)}

			{getFormSection(
				translations.contact,
				<>
					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.name} />}
						defaultValue={wayPointData.name || _get(wayPointData, 'customer.name' || '')}
						name={'name'}
						control={control}
					/>
					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.phone} />}
						defaultValue={wayPointData.phone || _get(wayPointData, 'customer.phone' || '')}
						name={'phone'}
						control={control}
					/>
					<Controller
						as={<Input className="waypoint-card-form-input" placeholder={translations.email} />}
						defaultValue={wayPointData.email || _get(wayPointData, 'customer.email' || '')}
						name={'email'}
						control={control}
					/>
				</>
			)}

			<div className="waypoint-card-form-buttons">
				<div>
					<Popconfirm
						overlayClassName="waypoint-card-form-confirm-delete"
						placement="topLeft"
						title={translations.confirmDelete}
						icon={<FontAwesomeIcon className="anticon" icon={faQuestionCircle} />}
						onConfirm={() => onDelete(wayPointData.id)}
						okText={translations.yes}
						cancelText={translations.no}
					>
						<ButtonWrapper danger type="primary" className="waypoint-card-form-button delete-button">
							Delete
						</ButtonWrapper>
					</Popconfirm>
				</div>

				<div>
					<ButtonWrapper className="waypoint-card-form-button" onClick={onCancelForm}>
						Cancel
					</ButtonWrapper>
					<ButtonWrapper
						type={'primary'}
						className="waypoint-card-form-button save-button"
						onClick={handleSubmit(onSubmit)}
					>
						Save
					</ButtonWrapper>
				</div>
			</div>
		</form>
	);
};

export default WayPointCardForm;
