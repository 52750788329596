import * as React from 'react';
import { DeliveryWindow } from '@bringg/types';
import SelectableList from '../../components/selectable-list/selectable-list';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';
import classNames from 'classnames';

interface Props {
	deliveryWindows: DeliveryWindow[];
	onDeliveryWindowSelect: (deliveryWindow: DeliveryWindow) => void;
	className?: string;
	style?: CSSProperties;
	deliveryWindowClassName?: string;
	deliveryWindowStyle?: CSSProperties;
	locale?: string;
	timezone?: string;
}

export const LOCALIZED_DATE_FORMAT = 'LLLL';
export const LOCALIZED_TIME_FORMAT = 'LT';

export const formatDateWithoutYear = (date: string, locale: string, timezone: string) => {
	const year = dayjs(date).format('YYYY');
	const localizedFormattedDate = formatDate(date, LOCALIZED_DATE_FORMAT, timezone, locale);

	// localized format without year
	return localizedFormattedDate.replace(` ${year}`, '');
};
const formatDate = (date: string, format: string, timezone?: string, locale?: string): string => {
	let formatDate = dayjs(date);

	if (timezone) {
		formatDate = formatDate.tz(timezone);
	}
	if (locale) {
		formatDate = formatDate.locale(locale);
	}

	return formatDate.format(format);
};
const normalizeDeliveryWindow = ({ start_time, end_time }: DeliveryWindow, locale?: string, timezone?: string) => {
	try {
		require(`dayjs/locale/${locale}.js`);
	} catch (e) {
		console.warn(`Failed loading locale: ${locale}`);
	}
	return `${formatDateWithoutYear(start_time, locale, timezone)} - ${formatDate(
		end_time,
		LOCALIZED_TIME_FORMAT,
		timezone,
		locale
	)}`;
};

const DeliveryWindows: React.FC<Props> = ({
	deliveryWindows,
	onDeliveryWindowSelect,
	className,
	deliveryWindowClassName,
	style,
	deliveryWindowStyle,
	locale = 'en',
	timezone = dayjs.tz.guess()
}) => {
	return (
		<SelectableList
			className={classNames('delivery-windows', className)}
			itemClassName={classNames('delivery-window', deliveryWindowClassName)}
			style={style}
			itemStyle={deliveryWindowStyle}
			items={deliveryWindows}
			onSelect={onDeliveryWindowSelect}
			renderValue={deliveryWindow => normalizeDeliveryWindow(deliveryWindow, locale, timezone)}
		/>
	);
};

export default DeliveryWindows;
