import { TaskStatus } from '@bringg/types';
import { Translations } from './task-status';

export enum StatusColor {
	GREEN = 'green',
	BLUE = 'blue',
	GRAY = 'gray',
	RED = 'red',
	BLACK = 'black'
}

export const getColorForTaskStatus = (status: TaskStatus): StatusColor => {
	switch (status) {
		case TaskStatus.Assigned:
		case TaskStatus.Accepted:
			return StatusColor.GREEN;

		case TaskStatus.OnTheWay:
		case TaskStatus.CheckedIn:
			return StatusColor.BLUE;

		case TaskStatus.Created:
		case TaskStatus.Cancelled:
		case TaskStatus.Rejected:
			return StatusColor.GRAY;

		case TaskStatus.Late:
			return StatusColor.RED;

		case TaskStatus.Done:
			return StatusColor.BLACK;
	}
};

export const getStatusTitle = (translations: Translations, status: TaskStatus) => {
	switch (status) {
		case TaskStatus.Created:
			return translations.notAssigned;
		case TaskStatus.Assigned:
			return translations.assigned;
		case TaskStatus.OnTheWay:
			return translations.onTheWay;
		case TaskStatus.CheckedIn:
			return translations.checkedIn;
		case TaskStatus.Done:
			return translations.done;
		case TaskStatus.Late:
			return translations.late;
		case TaskStatus.Accepted:
			return translations.accepted;
		case TaskStatus.Cancelled:
			return translations.cancelled;
		case TaskStatus.Rejected:
			return translations.rejected;
		case TaskStatus.Unacknowledged:
			return translations.unacknowledged;
		case TaskStatus.Pending:
			return translations.pending;
	}
};
