import React, { useCallback } from 'react';
import Collapse from '../../components/collapse/collapse';
import DateDisplay from '../../components/date-display/date-display';
import { Timeline } from 'antd';
import { User } from '@bringg/types';
import { Moment } from 'moment';

export const TIME_FORMAT = 'DD/MM/YY - HH:mm A';

export type ActivityLogTranslations = {
	collapseTitle: string;
	link: string;
	driver: string;
	showMore: string;
};

export type ActivityLogData = Partial<{
	link: string;
}>;

export interface LogProps {
	title: string;
	time: Date | Moment | string;
	user?: Partial<User>;
	data?: ActivityLogData;
}

export interface ActivityLogsProps {
	logs: LogProps[];
	onUserClick: (userId: number) => void;
	translations: ActivityLogTranslations;
	onShowMore: () => void;
}

export interface ActivityLogProps {
	log: LogProps;
	onClick: (userId: number) => void;
	translations: ActivityLogTranslations;
}

const ActivityLog: React.FC<ActivityLogProps> = ({ log, onClick, translations }) => {
	const { title, time, user, data } = log;
	const { id: userId, name: userName, driver } = user || {};
	const { link } = data || {};

	const handleClick = useCallback(
		function onUserClick() {
			onClick(userId);
		},
		[onClick, userId]
	);

	const userLinkTitle = `${userName}${driver ? ` (${translations.driver})` : ''}`;
	const userLink = userId && userName ? <a onClick={handleClick}>{userLinkTitle}</a> : null;

	const dataLink = link ? (
		<a href={link} className="activity-logs-timeline-item-link" target="_blank" rel="noopener noreferrer">
			{translations.link}
		</a>
	) : null;

	return (
		<Timeline.Item className="activity-logs-timeline-item">
			<span>
				{userLink && <span>{userLink} - </span>}
				{title}
				{dataLink}
			</span>
			<div className="activity-logs-timeline-item-date">
				<DateDisplay format={TIME_FORMAT} date={time} />
			</div>
		</Timeline.Item>
	);
};

const ActivityLogs: React.FC<ActivityLogsProps> = ({ logs, onUserClick, onShowMore, translations }) => {
	const timelineItems = logs.map((log, index) => (
		<ActivityLog key={index} log={log} onClick={onUserClick} translations={translations} />
	));

	return (
		<div className="activity-logs">
			<Collapse key="activity-log" header={translations.collapseTitle}>
				<Timeline className="activity-logs-timeline">{timelineItems}</Timeline>
			</Collapse>
			<a className="activity-logs-show-more" onClick={onShowMore}>
				{translations.showMore}
			</a>
		</div>
	);
};

export default ActivityLogs;
