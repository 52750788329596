import * as React from 'react';
import { Tree as AntdTree } from 'antd';
import { TreeProps as AntdTreeProps } from 'antd/lib/tree';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import _chain from 'lodash/flatten';
import _map from 'lodash/map';

const { TreeNode } = AntdTree;

interface Props extends AntdTreeProps {
	withHeader?: boolean;
	expandAllText?: string;
	collapseAllText?: string;
}

const getAllKeys = (treeData: Array<TreeNodeNormal>): string[] => {
	return _chain(treeData)
		.map(data => getAllKeysRecursive(data))
		.flat(1);
};

const getAllKeysRecursive = (treeNode: TreeNodeNormal): string[] => {
	const childrenKeys: string[] = _map(treeNode.children, getAllKeysRecursive).flat(1);
	return [treeNode.key.toString(), ...childrenKeys];
};

const Tree = ({
	className,
	withHeader,
	expandAllText = 'Expand All',
	collapseAllText = 'Collapse All',
	expandedKeys,
	treeData = [],
	...treeProps
}: Props) => {
	const combinedClassName = classNames('bringg-tree', className);
	const [expandedItems, setExpandedItems] = useState([]);

	useEffect(() => {
		setExpandedItems(expandedKeys || []);
	}, [expandedKeys]);

	const collapse = useCallback(() => {
		setExpandedItems([]);
	}, [expandedItems]);

	const expand = useCallback(() => {
		const keys = getAllKeys(treeData);
		setExpandedItems(keys);
	}, [expandedItems]);

	return (
		<div className="tree-wrapper">
			{withHeader && (
				<div className="button-container">
					<span className="tree-expand-button" onClick={expand}>
						{expandAllText}
					</span>
					<span className="tree-collapse-button" onClick={collapse}>
						{collapseAllText}
					</span>
				</div>
			)}
			<AntdTree
				className={combinedClassName}
				expandedKeys={expandedItems}
				treeData={treeData}
				onExpand={setExpandedItems}
				{...treeProps}
			/>
		</div>
	);
};

export default Tree;
export { Tree, TreeNode };
