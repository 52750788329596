import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import Button from '../button/button';
import _isNumber from 'lodash/isNumber';
import { CSSProperties } from 'react';
import classNames from 'classnames';

interface Props {
	label: string;
	minValue?: number;
	maxValue?: number;
	defaultValue?: number;
	onChange?: (value: number) => void;
	value?: number;
	disabled?: boolean;
	style?: CSSProperties;
	className?: string;
}

interface State {
	value: number;
}

class LabelNumberInput extends React.PureComponent<Props, State> {
	state = {
		value: this.props.value || this.props.defaultValue || 0
	};

	static getDerivedStateFromProps(nextProps) {
		if ('value' in nextProps) {
			return {
				value: nextProps.value || 0
			};
		}
		return null;
	}

	decreaseValue = () => this.setState(prevState => ({ value: prevState.value - 1 }));

	increaseValue = () => this.setState(prevState => ({ value: prevState.value + 1 }));

	handleDecrease = () => {
		if (!('value' in this.props)) {
			this.decreaseValue();
		}

		this.triggerChange(this.state.value - 1);
	};

	handleIncrease = () => {
		if (!('value' in this.props)) {
			this.increaseValue();
		}

		this.triggerChange(this.state.value + 1);
	};

	triggerChange = value => {
		const { onChange } = this.props;
		if (onChange) {
			onChange(value);
		}
	};

	render() {
		const { handleDecrease, handleIncrease } = this;
		const { label, minValue, maxValue, disabled, style, className } = this.props;
		const { value } = this.state;
		return (
			<div className={classNames('label-number-input', className)} style={style}>
				<Button
					className={`${disabled ? 'angles-disabled' : 'angles'}`}
					disabled={disabled || (_isNumber(minValue) && minValue >= value)}
					onClick={handleDecrease}
				>
					<FontAwesomeIcon icon={faAngleDown} />
				</Button>
				<div className={`${disabled ? 'label-number-input-text-disabled' : 'label-number-input-text'}`}>
					{`${value} ${label}`}
				</div>
				<Button
					className={`${disabled ? 'angles-disabled' : 'angles'}`}
					disabled={disabled || (_isNumber(maxValue) && maxValue <= value)}
					onClick={handleIncrease}
				>
					<FontAwesomeIcon icon={faAngleUp} />
				</Button>
			</div>
		);
	}
}

export default LabelNumberInput;
