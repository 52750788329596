import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import Input from '../../floating-placeholder-input/input';
import React from 'react';

interface Props {
	search: string;
	onSearch: (value: string) => void;
	next: () => void;
	previous: () => void;
	activeIndex: number;
	total: number;
	placeholder?: string;
}

const SearchInput: React.FC<Props> = ({ search, onSearch, next, previous, activeIndex, total, placeholder }) => {
	const onChange = event => {
		onSearch(event.target.value);
	};

	return (
		<div className="search-input">
			<Input
				className="search-input-input"
				placeholder={placeholder ?? 'Search'}
				value={search}
				onChange={onChange}
			/>
			<div className="icons-wrapper">
				{search !== '' && (
					<>
						<span className="count">{`${total ? activeIndex + 1 : 0}/${total}`}</span>
						<FontAwesomeIcon icon={faChevronUp} onClick={previous} className="previous-icon" />
						<FontAwesomeIcon icon={faChevronDown} onClick={next} className="next-icon" />
					</>
				)}
				<FontAwesomeIcon icon={faSearch} className="search-icon" />
			</div>
		</div>
	);
};

export default SearchInput;
