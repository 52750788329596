import React from 'react';
import Collapse from '../../components/collapse/collapse';
import { Skill, Tag, Task, Team } from '@bringg/types';
import AssignComponent, {
	Translations as AssignComponentTranslations
} from '../../components/assign-component/assign-component';
import { default as _isNil } from 'lodash/isNil';
import { default as _map } from 'lodash/map';
import { default as _find } from 'lodash/find';
import { default as _first } from 'lodash/first';
import { default as _noop } from 'lodash/noop';
import TaskStatus, { Translations as TaskStatusTranslations } from '../../components/task-status/task-status';
import LinkedTasks, { Translations as LinkedTasksTranslations } from '../../components/linked-tasks/linked-tasks';
import MultiTagsSelect from '../../components/multi-tags-select/multi-tags-select';
import Label from '../../components/label/label';
import TaskPromisedTimeWindow, {
	PromisedTimeWindow
} from '../../components/task-promised-time-window/task-promised-time-window';
import DateDisplay, { getDateFormat, getTimeFormat } from '../../components/date-display/date-display';
import Timezone from '../../components/timezone/timezone';
import { OrderPageConfiguration } from '../waypoint-card/wapoint-card';
import { StarRating } from '../../components';
import moment, { Moment } from 'moment';

export interface Translations extends TaskStatusTranslations, AssignComponentTranslations, LinkedTasksTranslations {
	generalInfo: string;
	status: string;
	startedAt: string;
	team: string;
	tags: string;
	customer: string;
	linkedOrders: string;
	skills: string;
	selectTag: string;
	selectSkills: string;
	promisedTime: string;
	servicePlan: string;
	parentTaskId: string;
	rating: string;
	createdAt: string;
}

interface TaskGeneralInfoProps {
	task: Task;
	linkedTask?: Task;
	timezone?: string;
	promisedTimeWindow?: PromisedTimeWindow;
	hourFormat: string;
	teams?: Team[];
	tags?: Tag[];
	skills?: Skill[];
	onAssignedTeam?: (teamId: number) => void;
	onSkillsChanged?: (skills: string[]) => void;
	translations: Translations;
	onParentClick?: () => void;
	enableSkills?: boolean;
	editable?: boolean;
	configuration?: OrderPageConfiguration;
	onStatusClick?: () => void;
	statusHoverText?: string;
	rating?: Rating;
}
export interface Rating {
	rating: number;
	created_at: string | Moment;
}
const getGeneralInfoItem = (shouldShow: boolean, title: string, content: React.ReactNode) => {
	if (!shouldShow) {
		return;
	}

	return (
		<div className="general-info-item">
			<div className="general-info-item-title">{title}</div>

			{content}
		</div>
	);
};

const TaskGeneralInfo: React.FC<TaskGeneralInfoProps> = ({
	task,
	linkedTask,
	timezone,
	promisedTimeWindow,
	hourFormat = 'LT',
	teams,
	tags,
	skills,
	onAssignedTeam,
	onSkillsChanged,
	translations,
	onParentClick = _noop,
	enableSkills,
	editable = true,
	configuration,
	onStatusClick,
	statusHoverText,
	rating
}) => {
	const skillsValues = _map(skills, 'name');
	const selectedTag = _find(tags, tag => tag.id === task.tag_id);
	const { promisedStartTime, promisedEndTime } = promisedTimeWindow || {};
	const timeZoneForDates = configuration?.flags?.useTeamTimezone && timezone;

	return (
		<div className="task-general-info">
			<Collapse key={`${task.id}-general-info`} header={translations.generalInfo}>
				{getGeneralInfoItem(
					!_isNil(promisedStartTime) && !_isNil(promisedEndTime),
					translations.promisedTime,
					<TaskPromisedTimeWindow
						hourFormat={hourFormat}
						promisedTimeWindow={promisedTimeWindow}
						timezone={timezone}
						useTeamTimezone={configuration?.flags?.useTeamTimezone}
					/>
				)}

				{getGeneralInfoItem(
					!_isNil(task.service_plan),
					translations.servicePlan,
					<Label className="task-general-info-label" text={task.service_plan?.name} />
				)}

				{getGeneralInfoItem(
					!_isNil(task.status),
					translations.status,
					<TaskStatus
						hoverText={statusHoverText}
						onClick={onStatusClick}
						task={task}
						dateFormat={getTimeFormat(hourFormat)}
						translations={translations}
						timezone={timeZoneForDates}
					/>
				)}

				{getGeneralInfoItem(
					!_isNil(task.started_time),
					translations.startedAt,
					<div>
						<span className="task-general-info-date">
							<DateDisplay
								date={task.started_time}
								format={getDateFormat(hourFormat)}
								timezone={timeZoneForDates}
							/>
						</span>

						<Timezone timezone={timezone} />
					</div>
				)}

				{getGeneralInfoItem(
					!_isNil(task.parent_task_id),
					translations.parentTaskId,
					<div>
						<span className="task-general-info-parent-task-id" onClick={onParentClick}>
							{`#${task.parent_task_id}`}
						</span>
					</div>
				)}

				{getGeneralInfoItem(
					!_isNil(teams),
					translations.team,
					<AssignComponent
						items={teams}
						disabled={!editable}
						enableUnassign={false}
						selectedItemId={_first(task.team_ids)}
						onSelectedItem={onAssignedTeam}
						translations={translations}
					/>
				)}

				{getGeneralInfoItem(
					!_isNil(task.tag_id),
					translations.tags,
					<Label className="task-general-info-label" text={selectedTag?.tag} />
				)}

				{getGeneralInfoItem(
					!_isNil(task.customer_name),
					translations.customer,
					<span className="customer-info">{task.customer_name}</span>
				)}

				{getGeneralInfoItem(
					!_isNil(linkedTask),
					translations.linkedOrders,
					<LinkedTasks task={task} linkedTask={linkedTask} translations={translations} />
				)}

				{getGeneralInfoItem(
					enableSkills,
					translations.skills,
					<MultiTagsSelect
						disabled={!editable}
						placeholder={translations.selectSkills}
						defaultValue={task.required_skills}
						tags={skillsValues}
						onTagsChange={onSkillsChanged}
					/>
				)}
				{getGeneralInfoItem(
					!_isNil(rating),
					translations.rating,
					<>
						<StarRating rating={rating?.rating} />
						<div>{`${translations.createdAt}: ${moment(rating?.created_at).calendar()}`}</div>
					</>
				)}
			</Collapse>
		</div>
	);
};

export default TaskGeneralInfo;
