import React, { useEffect, useMemo, useState } from 'react';
import useMatchingNodes from './hooks/use-matching-nodes/use-matching-nodes';
import useNextPrevious from '../../../../search/hooks/use-search-next-previous/use-next-previous';
import SearchInput from '../../../../search/search-input/search-input';
import TreeWithHighlights, { Props as TreeWithHighlightProps } from './tree-with-highlights/tree-with-highlights';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

interface Props extends TreeWithHighlightProps {
	className?: string;
	treeClassName?: string;
	searchPlaceholder?: string;
	emptyTreeTranslation?: string;
}

const TreeWithSearch: React.FC<Props> = ({
	tree,
	rootId,
	nodeValues,
	searchPlaceholder,
	emptyTreeTranslation,
	treeClassName,
	className,
	...otherProps
}) => {
	const [search, setSearch] = useState('');
	const searchedNodes = useMatchingNodes(tree, rootId, search);
	const searchedItems = useMemo(() => searchedNodes.map(nodeId => ({ value: tree[nodeId].option, id: nodeId })), [
		searchedNodes,
		tree
	]);
	const { next, previous, activeIndex, activeId: activeNodeId } = useNextPrevious(searchedItems);

	useEffect(() => {
		setSearch('');
	}, [tree, nodeValues]);

	return (
		<div className={classNames('tree-with-search', className)}>
			<SearchInput
				onSearch={setSearch}
				search={search}
				next={next}
				previous={previous}
				activeIndex={activeIndex}
				total={searchedNodes.length}
				placeholder={searchPlaceholder}
			/>
			{_isEmpty(tree) && emptyTreeTranslation ? (
				<div className="no-tree">{emptyTreeTranslation}</div>
			) : (
				<TreeWithHighlights
					tree={tree}
					rootId={rootId}
					activeNodeId={activeNodeId}
					nodeValues={nodeValues}
					nodeIdsToHighlight={searchedNodes}
					textToHighlight={search}
					className={treeClassName}
					{...otherProps}
				/>
			)}
		</div>
	);
};

export default TreeWithSearch;
