// TODO: probably move the whole component to @bringg/react-components
import React, { memo, useMemo, useState, useCallback, ChangeEvent, useEffect } from 'react';
import { Dropdown, Checkbox, Input } from 'antd';
import classnames from 'classnames';
import { FixedSizeList } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

import SelectAll from './select-all';

import './multiselect.scss';

const FILTERS_SEARCH_THRESHOLD = 10;
const MULTISELECT_FILTER_ITEM_HEIGHT = 35; // in px
const MULTISELECT_FILTER_ITEMS_IN_VIEW = 10.5; // count
const MULTISELECT_FILTER_CONFIG = {
	itemSize: MULTISELECT_FILTER_ITEM_HEIGHT,
	maxHeight: MULTISELECT_FILTER_ITEM_HEIGHT * MULTISELECT_FILTER_ITEMS_IN_VIEW
};

export interface Option {
	id: number;
	name: string;
	entityName?: string;
}

export interface MultiselectTranslations {
	unknowItemText: string;
	seachItemPlaceholder: string;
	multiselectPlaceholder: string;
	oneItemSelected: string;
	multipleItemsSelected: string;
	allItemsSelected: string;
	clearSelectionText: string;
	selectAllText: string;
}

interface GetLabelProps {
	value: number[];
	options: Array<Option>;
	translations: MultiselectTranslations;
}

export interface MultiselectProps extends GetLabelProps {
	onChange: (value: number[]) => void;
}

const ColumnsMenuVirtualized = memo(({ value, onChange, options, translations }: MultiselectProps) => {
	const [searchQuery, setSearchQuery] = useState<string>('');
	const valuesSet = useMemo(() => new Set(value), [value]);
	const { itemSize, maxHeight } = MULTISELECT_FILTER_CONFIG;

	const searchEnabled = options.length > FILTERS_SEARCH_THRESHOLD;
	const allChecked = options.every(item => valuesSet.has(item.id));

	const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value), []);
	const handleCheckAll = useCallback(() => {
		onChange(allChecked ? [] : options.map(({ id }) => id));
	}, [onChange, options, allChecked]);

	const filteredOptions = useMemo(() => {
		const normalizedSearchQuery = searchQuery.trim().toLowerCase();
		return searchEnabled && normalizedSearchQuery
			? options.filter(({ name }) => name?.toLowerCase().includes(normalizedSearchQuery) ?? false)
			: options;
	}, [options, searchQuery, searchEnabled]);

	useEffect(() => setSearchQuery(''), [options]);

	const OuterElementType = useCallback(
		({ style, onScroll, children }) => (
			<ul style={style} onScroll={onScroll} className="ant-menu filters-menu" role="menu">
				{children}
			</ul>
		),
		[]
	);
	const RenderRow = useCallback(
		({ index, style }) => {
			const { id, name, entityName } = filteredOptions[index];
			const checked = valuesSet.has(id);
			const itemName = name || `${entityName} ${id}` || translations.unknowItemText; // TODO: entityName
			const setChecked = () => {
				onChange(checked ? value.filter(v => v !== id) : value.concat(id));
			};

			return (
				<li
					key={id}
					onClick={setChecked}
					className="ant-menu-item"
					role="menuitem"
					style={style}
					title={itemName}
				>
					<Checkbox checked={checked} />
					{itemName}
				</li>
			);
		},
		[filteredOptions, valuesSet, onChange, value]
	);

	return (
		<div className="filter-overlay-wrapper">
			{searchEnabled && (
				<Input
					className="search-input"
					suffix={<FontAwesomeIcon icon={faSearch} />}
					placeholder={translations.seachItemPlaceholder}
					value={searchQuery}
					onChange={handleSearchChange}
				/>
			)}
			<SelectAll
				isAllSelected={allChecked}
				handleClick={handleCheckAll}
				clearSelectionText={translations.clearSelectionText}
				selectAllText={translations.selectAllText}
			/>
			<FixedSizeList
				itemSize={itemSize}
				height={Math.min(maxHeight, filteredOptions.length * itemSize)}
				itemCount={filteredOptions.length}
				width="100%"
				outerElementType={OuterElementType}
			>
				{RenderRow}
			</FixedSizeList>
		</div>
	);
});

const getFilterValueLabel = ({ value, options, translations }: GetLabelProps) => {
	const { multiselectPlaceholder, oneItemSelected, multipleItemsSelected, allItemsSelected } = translations;

	let label = '';

	if (!value.length) {
		return <span className="multiselect-placeholder">{multiselectPlaceholder}</span>;
	}
	if (value.length < options.length) {
		const { length } = value;
		const text = length === 1 ? oneItemSelected : multipleItemsSelected;
		label = `${length} ${text}`;
	}
	if (value.length === options.length) {
		label = allItemsSelected;
	}

	return <span className="multiselect-label">{label}</span>;
};

type MultiselectTrigger = 'click' | 'hover' | 'contextMenu';

const FilterMultiselect = (props: MultiselectProps) => {
	const { options, value, translations } = props;
	const [menuVisible, setMenuVisible] = useState<boolean>(false);

	const className = classnames('multiselect-wrapper', {
		active: menuVisible
	});
	const trigger: MultiselectTrigger[] = useMemo(() => ['click'], []);
	const filterValueLabel = useMemo(() => getFilterValueLabel({ options, value, translations }), [
		options,
		value,
		translations
	]);

	// TODO: use renderValue prop to render multiselect-label
	return (
		<Dropdown overlay={<ColumnsMenuVirtualized {...props} />} trigger={trigger} onVisibleChange={setMenuVisible}>
			<div className={className}>
				<div className="multiselect-text-wrapper">{filterValueLabel}</div>
				<FontAwesomeIcon icon={menuVisible ? faCaretUp : faCaretDown} />
			</div>
		</Dropdown>
	);
};

export default memo(FilterMultiselect);
